import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import cn from 'classnames';

import { EmptyState, useRecentlyOrderedProducts } from 'components';

import ProductWithAllSuppliers from '../Product/containers/ProductWithAllSuppliers';
import ProductWithSelectedSuppliers from '../Product/containers/ProductWithSelectedSuppliers';

import style from '../../CatalogAndCartController.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsList = ({
  status,
  showRefPrice,
  isCatalogView,
  isQuietLoading,
  productClasses,
  selectedCompany,
  products = [],
  changeValuationSelections
}) => {
  const { listUID } = useParams();

  const recentlyOrdered = useRecentlyOrderedProducts(listUID);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);
  const ProductComponent = isDefinedView ? ProductWithSelectedSuppliers : ProductWithAllSuppliers;

  if (products.length === 0) {
    return (
      <section className={classNames(shared.tableWrapper, style.tableWrapper, { [style.elastic]: isDefinedView })}>
        <div className={cn(style.box, style.center, { [style.loadingAnimation]: isQuietLoading })}>
          <EmptyState type={'noPrices'} />
        </div>
      </section>
    );
  }

  return (
    <div className={style.leftColumn}>
      <section
        className={classNames(shared.tableWrapper, style.tableWrapper)}
        id={'valuation-table'}
      >
        {isDefinedView && (
          <div className={cn(style.tableHeader, { [style.withRefPrices]: showRefPrice })}>
            <p className={style.supplierLabel}>Produkt</p>
            {showRefPrice && <p className={style.supplierLabel}>Informacje</p>}
            <p className={style.supplierLabel}>Oferta preferowana</p>
            <p className={style.supplierLabel}>Oferta alternatywna</p>
            <p className={style.supplierLabel}>Oferta zapasowa</p>
          </div>
        )}
        {!isDefinedView && (
          <div className={cn(style.tableHeader, style.withAll, { [style.withRefPrices]: showRefPrice })}>
            <p className={style.supplierLabel}>Produkt</p>
            <p className={style.supplierLabel}>Oferty</p>
          </div>
        )}
        <div className={cn(style.box, { [style.loadingAnimation]: isQuietLoading })}>
          {products.map((product) => (
            <ProductComponent
              status={status}
              key={product.id}
              product={product}
              className={productClasses}
              showRefPrice={showRefPrice}
              isCatalogView={isCatalogView}
              selectedCompany={selectedCompany}
              changeValuationSelections={changeValuationSelections}
              recentlyOrdered={recentlyOrdered.find((prod) => prod.product_id === product.product_id)}
              isQuietLoading={isQuietLoading}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ProductsList;
