import ApiClient from 'src/api/client';
import {
  AccountsRoutes,
  AdminRoutes,
  CompanyRoutes,
  DistributorRoutes,
  ExpensesRoutes,
  InvoicesRoutes,
  LandingRoutes,
  ListsRoutes,
  OCRInvoicesRoutes,
  OfferCatalogRoutes,
  OrdersRoutes,
  PermissionsRoutes,
  ProductsRoutes,
  ReplacementsRoutes,
  ServicesRoutes,
  StatisticsRoutes,
  SupplierRoutes,
  TagsRoutes,
  UserRoutes,
  ValuationsRoutes,
  WarehouseRoutes,
  WholesalersRoutes
} from 'src/api/routes';

const formDataHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

const responseTypeBlob = {
  responseType: 'blob'
};

export class AccountsApi {
  static forgotPassword(data) {
    return ApiClient.post(AccountsRoutes.FORGOT_PASSWORD, data);
  }
  static resetPassword(data) {
    return ApiClient.post(AccountsRoutes.RESET_PASSWORD, data);
  }
  static changePassword(data) {
    return ApiClient.post(AccountsRoutes.ACCOUNT_CHANGE_PASSWORD, data);
  }
  static getAttachment(params) {
    return ApiClient.get(AccountsRoutes.GET_ATTACHMENT, params, responseTypeBlob);
  }
  static getShipAndPayMethods(params) {
    return ApiClient.get(AccountsRoutes.GET_SHIP_AND_PAY_METHODS, params);
  }
}

export class CompanyApi {
  static updateCompanyData(data) {
    return ApiClient.post(CompanyRoutes.UPDATE_COMPANY, data);
  }
  static createNewCompany(data) {
    return ApiClient.post(CompanyRoutes.CREATE_NEW_COMPANY, data);
  }
  static getAllCompanies() {
    return ApiClient.get(CompanyRoutes.GET_ALL_COMPANIES);
  }
  static updateShippingDetails(data) {
    return ApiClient.put(CompanyRoutes.ADD_SHIPPING_ADDRESS, data);
  }
  static getMembers() {
    return ApiClient.get(CompanyRoutes.GET_USERS);
  }
  static getInvitesList() {
    return ApiClient.get(CompanyRoutes.GET_INVITES_LIST);
  }
  static deleteShippingDetails(id) {
    return ApiClient.delete(CompanyRoutes.DELETE_SHIPPING_DETAILS(id));
  }
  static deleteCompanyMember(data) {
    return ApiClient.post(CompanyRoutes.DELETE_COMPANY_MEMBER, data);
  }
  static cancelInvitation(data) {
    return ApiClient.post(CompanyRoutes.CANCEL_INVITATION, data);
  }
  static saveLogo(data) {
    return ApiClient.post(CompanyRoutes.SAVE_LOGO, data);
  }
  static getProductsWithSuppliers(params, signal) {
    return ApiClient.get(CompanyRoutes.COLLECTION_GET, params, { signal });
  }
  static addSupplierToProduct(data) {
    return ApiClient.post(CompanyRoutes.ADD_SUPPLIER_TO_PRODUCT, data);
  }
  static removeSupplierFromProduct(data) {
    return ApiClient.post(CompanyRoutes.REMOVE_SUPPLIER_FROM_PRODUCT, data);
  }
  static getThresholds(params) {
    return ApiClient.get(CompanyRoutes.GET_THRESHOLDS, params);
  }
  static addThreshold(params) {
    return ApiClient.post(CompanyRoutes.ADD_THRESHOLDS, params);
  }
  static deleteThreshold(id) {
    return ApiClient.delete(CompanyRoutes.DELETE_THRESHOLDS, { id });
  }
  static editThreshold(data) {
    return ApiClient.put(CompanyRoutes.EDIT_THRESHOLD, data);
  }
  static getEmployees(params) {
    return ApiClient.get(CompanyRoutes.GET_EMPLOYEES, params);
  }
  static createEmployee(data) {
    return ApiClient.post(CompanyRoutes.CREATE_EMPLOYEE, data);
  }
  static editCompanyUser(data) {
    return ApiClient.post(CompanyRoutes.EDIT_COMPANY_USER, data);
  }
  static addEmployee(data) {
    return ApiClient.post(CompanyRoutes.ADD_EMPLOYEE, data);
  }
  static sendWelcomeMessage(id) {
    return ApiClient.post(CompanyRoutes.SEND_WELCOME_MESSAGE(id));
  }
  static getUsersValuationsCreate(params) {
    return ApiClient.get(CompanyRoutes.GET_USERS_VALUATIONS_CREATE, params);
  }
  static addUserToCompany(data, signal) {
    return ApiClient.post(CompanyRoutes.ADD_USER_TO_COMPANY, data, { signal });
  }
}

export class UserApi {
  static me() {
    return ApiClient.get(UserRoutes.ME);
  }
  static getDistributors(params) {
    return ApiClient.get(UserRoutes.GET_DISTRIBUTORS, params);
  }
  static addSupplier(data) {
    return ApiClient.post(UserRoutes.ADD_SUPPLIER, data);
  }
  static editSupplier(data) {
    return ApiClient.post(UserRoutes.EDIT_SUPPLIER, data);
  }
  static deleteSupplier({ id }) {
    return ApiClient.delete(UserRoutes.DELETE_SUPPLIER(id));
  }
  static getProducts(params, signal) {
    return ApiClient.get(UserRoutes.GET_USER_PRODUCTS, params, { signal });
  }
  static getProductCreationData(params) {
    return ApiClient.get(UserRoutes.GET_USER_PROD_CREATION_DATA, params);
  }
  static addProduct(params) {
    return ApiClient.put(UserRoutes.ADD_PRODUCT, params);
  }
  static addProducer(name) {
    return ApiClient.put(UserRoutes.ADD_PRODUCER, { name });
  }
  static getNotifications(params, signal) {
    return ApiClient.get(UserRoutes.GET_NOTIFICATIONS, params, { signal });
  }
  static getUnreadNotifications(params, signal) {
    return ApiClient.get(UserRoutes.GET_UNREAD_NOTIFICATIONS, params, { signal });
  }
  static setNotificationAsRead(id) {
    return ApiClient.post(UserRoutes.SET_NOTIFICATION_AS_READ, { id });
  }
  static setAllNotificationsAsRead() {
    return ApiClient.post(UserRoutes.SET_ALL_NOTIFICATIONS_AS_READ);
  }
  static updateUserData(data) {
    return ApiClient.put(UserRoutes.UPDATE_USER_DATA, data);
  }
  static saveDashboardConfig(data) {
    return ApiClient.put(UserRoutes.SAVE_DASHBOARD_CONFIG, { dashboard_config: data });
  }
}

export class ListsApi {
  static getLists(params, signal) {
    return ApiClient.get(ListsRoutes.GET_LISTS, params, { signal });
  }
  static createList(data) {
    return ApiClient.post(ListsRoutes.CREATE_LIST, data);
  }
  static mergeLists(data) {
    return ApiClient.post(ListsRoutes.MERGE_LISTS, data);
  }
  static moveList(data) {
    return ApiClient.post(ListsRoutes.MOVE_LIST, data);
  }
  static editListName(data) {
    return ApiClient.post(ListsRoutes.EDIT_LIST_NAME, data);
  }
  static getListProducts(uid, signal) {
    return ApiClient.post(ListsRoutes.GET_LIST_PRODUCTS, { uid }, { signal });
  }
  static storeToList(data, signal) {
    return ApiClient.post(ListsRoutes.STORE_PRODUCT_TO_LIST, data, { signal });
  }
  static deleteProduct(uid, product_ids) {
    return ApiClient.post(ListsRoutes.DELETE_PRODUCTS, { uid, product_ids });
  }
  static deleteList(uid) {
    return ApiClient.delete(ListsRoutes.DELETE_LIST(uid));
  }
  static createPricing(data) {
    return ApiClient.post(ListsRoutes.CREATE_PRICING, data);
  }
  static createHorizontalPricing(data) {
    return ApiClient.post(ListsRoutes.CREATE_HORIZONTAL_PRICING, data);
  }
  static ListDuplicate(data) {
    return ApiClient.post(ListsRoutes.LIST_DUPLICATE, data);
  }
  static saveListComment(data, signal) {
    return ApiClient.post(ListsRoutes.SAVE_LIST_COMMENT, data, { signal });
  }
  static setProductsComment(data) {
    return ApiClient.post(ListsRoutes.SET_PRODUCTS_COMMENT, data);
  }
  static getData(data, signal) {
    return ApiClient.get(ListsRoutes.GET_CART_DATA, data, { signal });
  }
  static getSummary(params, signal) {
    return ApiClient.get(ListsRoutes.GET_CART_SUMMARY, params, { signal });
  }
  static removeAllSelects(data) {
    return ApiClient.post(ListsRoutes.REMOVE_ALL_SELECTS, data);
  }
  static optimizeOfferCatalog(data) {
    return ApiClient.post(ListsRoutes.OPTIMIZE_OFFER_CATALOG, data);
  }
  static selectPreferred(data) {
    return ApiClient.post(ListsRoutes.SELECT_PREFERRED, data);
  }
  static setPreferredSuppliers(data) {
    return ApiClient.post(ListsRoutes.SET_PREFERRED_SUPPLIERS, data);
  }
  static selectPrice(data) {
    return ApiClient.post(ListsRoutes.SELECT_PRICE, data);
  }
  static resolveOrderData(params, signal) {
    return ApiClient.get(ListsRoutes.RESOLVE_ORDER_DATA, params, { signal });
  }
  static createOrder(data) {
    return ApiClient.post(ListsRoutes.CREATE_ORDER, data);
  }
  static getListActions(list_uid) {
    return ApiClient.get(ListsRoutes.GET_LIST_ACTIONS, { list_uid });
  }
  static getRecentlyOrderedProducts(list_uid) {
    return ApiClient.get(ListsRoutes.GET_RECENTLY_ORDERED_PRODUCTS, { list_uid });
  }
  static getOfferCatalogProductsWithoutPrice(params) {
    return ApiClient.get(ListsRoutes.GET_OFFER_CATALOG_PRODUCTS_WITHOUT_PRICE, params);
  }
}

export class ExpensesApi {
  static getCategories(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_CATEGORIES, params, { signal });
  }
  static getExpenseHistory(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_HISTORY, params, { signal });
  }

  static getUserExpenses(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_USER_EXPENSES, params, { signal });
  }
  static addUserExpense(data) {
    return ApiClient.post(ExpensesRoutes.ADD_USER_EXPENSE, data, formDataHeaders);
  }
  static editUserExpense(data) {
    return ApiClient.post(ExpensesRoutes.EDIT_USER_EXPENSE, data, formDataHeaders);
  }
  static deleteUserExpense(data) {
    return ApiClient.post(ExpensesRoutes.DELETE_USER_EXPENSE, data);
  }
  static addExpenseFiles(data) {
    return ApiClient.post(ExpensesRoutes.ADD_EXPENSE_FILES, data, formDataHeaders);
  }
  static deleteExpenseFile(data) {
    return ApiClient.post(ExpensesRoutes.DELETE_EXPENSE_FILE, data, formDataHeaders);
  }
  static downloadExpenseFile(params) {
    return ApiClient.get(ExpensesRoutes.DOWNLOAD_EXPENSE_FILE, params, responseTypeBlob);
  }
  static getUserExpensePayments(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_USER_EXPENSE_PAYMENTS, params, { signal });
  }
  static addUserExpensePayment(data) {
    return ApiClient.post(ExpensesRoutes.ADD_USER_EXPENSE_PAYMENT, data, formDataHeaders);
  }
  static editUserExpensePayment(data) {
    return ApiClient.post(ExpensesRoutes.EDIT_USER_EXPENSE_PAYMENT, data, formDataHeaders);
  }
  static deleteUserExpensePayment(data) {
    return ApiClient.post(ExpensesRoutes.DELETE_USER_EXPENSE_PAYMENT, data);
  }
  static addExpensePaymentFiles(data) {
    return ApiClient.post(ExpensesRoutes.ADD_EXPENSE_PAYMENT_FILES, data, formDataHeaders);
  }
  static deleteExpensePaymentFile(data) {
    return ApiClient.post(ExpensesRoutes.DELETE_EXPENSE_PAYMENT_FILE, data, formDataHeaders);
  }
  static downloadExpensePaymentFile(params) {
    return ApiClient.get(ExpensesRoutes.DOWNLOAD_EXPENSE_PAYMENT_FILE, params, responseTypeBlob);
  }
  static getExpensesGrouped(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_EXPENSES_GROUPED, params, { signal });
  }
  static getExpensePaymentsGrouped(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_EXPENSE_PAYMENTS_GROUPED, params, { signal });
  }
  static getTags(params, signal) {
    return ApiClient.get(ExpensesRoutes.GET_TAGS, params, { signal });
  }
  static addTag(params, signal) {
    return ApiClient.post(ExpensesRoutes.ADD_TAG, params, { signal });
  }
  static editTag(params, signal) {
    return ApiClient.post(ExpensesRoutes.EDIT_TAG, params, { signal });
  }
  static deleteTag(id, signal) {
    return ApiClient.post(ExpensesRoutes.DELETE_TAG, { id }, { signal });
  }
  static addCategory(params) {
    return ApiClient.post(ExpensesRoutes.ADD_CATEGORY, params, formDataHeaders);
  }
  static editCategory(params) {
    return ApiClient.post(ExpensesRoutes.EDIT_CATEGORY, params, formDataHeaders);
  }
  static deleteCategory(id) {
    return ApiClient.post(ExpensesRoutes.DELETE_CATEGORY, { id });
  }
}

export class InvoicesApi {
  static getInvoices(params, signal) {
    return ApiClient.get(InvoicesRoutes.GET_INVOICES, params, { signal });
  }
  static addUserInvoice(data) {
    return ApiClient.post(InvoicesRoutes.ADD_USER_INVOICE, data, formDataHeaders);
  }
  static addUserInvoiceOther(data) {
    return ApiClient.post(InvoicesRoutes.ADD_USER_INVOICE_OTHER, data, formDataHeaders);
  }
  static editUserInvoice(data) {
    return ApiClient.post(InvoicesRoutes.EDIT_USER_INVOICE, data, formDataHeaders);
  }
  static editUserInvoiceOther(data) {
    return ApiClient.post(InvoicesRoutes.EDIT_USER_INVOICE_OTHER, data, formDataHeaders);
  }
  static downloadInvoiceOther(params) {
    return ApiClient.get(InvoicesRoutes.DOWNLOAD_INVOICE_OTHER, params, responseTypeBlob);
  }
  static downloadInvoice(params) {
    return ApiClient.get(InvoicesRoutes.DOWNLOAD_INVOICE, params, responseTypeBlob);
  }
  static deleteUserInvoice(data) {
    return ApiClient.post(InvoicesRoutes.DELETE_USER_INVOICE, data);
  }
  static deleteUserInvoiceOther(data) {
    return ApiClient.post(InvoicesRoutes.DELETE_USER_INVOICE_OTHER, data);
  }
  static getActions(data) {
    return ApiClient.get(InvoicesRoutes.GET_ACTIONS, data);
  }
  static bulkStatusChange(data) {
    return ApiClient.post(InvoicesRoutes.BULK_STATUS_CHANGE, data);
  }
}

export class OCRInvoicesApi {
  static getInvoicesList(params, signal) {
    return ApiClient.get(OCRInvoicesRoutes.GET_INVOICES_LIST, params, { signal });
  }
  static linkInvoiceProduct(params, signal) {
    return ApiClient.post(OCRInvoicesRoutes.LINK_INVOICE_PRODUCT, params, { signal });
  }
  static editInvoice(params) {
    return ApiClient.post(OCRInvoicesRoutes.EDIT_INVOICE, params);
  }
  static deleteInvoice(invoice_id) {
    return ApiClient.delete(OCRInvoicesRoutes.DELETE_INVOICE, { invoice_id });
  }
  static editInvoiceProduct(params) {
    return ApiClient.post(OCRInvoicesRoutes.EDIT_INVOICE_PRODUCT, params);
  }
  static getInvoiceDetails(invoice_id, signal) {
    return ApiClient.get(OCRInvoicesRoutes.GET_INVOICE_DETAILS, { invoice_id }, { signal });
  }
  static uploadInvoice(data) {
    return ApiClient.post(OCRInvoicesRoutes.UPLOAD_INVOICE, data, formDataHeaders);
  }
  static downloadFile(file_name) {
    return ApiClient.get(OCRInvoicesRoutes.DOWNLOAD_INVOICE, { file_name }, responseTypeBlob);
  }
  static analyzeInvoice(doc_id) {
    return ApiClient.post(OCRInvoicesRoutes.ANALYZE_INVOICE, { doc_id });
  }
  static fillOfferCatalog(data) {
    return ApiClient.post(OCRInvoicesRoutes.FILL_OFFER_CATALOG, data);
  }
  static assignProducts(invoice_id) {
    return ApiClient.post(OCRInvoicesRoutes.ASSIGN_PRODUCTS, { invoice_id });
  }
  static deleteInvoiceProduct(product_id) {
    return ApiClient.delete(OCRInvoicesRoutes.DELETE_INVOICE_PRODUCT, { invoice_product_id: product_id });
  }
  static addInvoiceProduct(params) {
    return ApiClient.put(OCRInvoicesRoutes.ADD_INVOICE_PRODUCT, params);
  }
}

export class ProductsApi {
  static getProducts(params, signal) {
    return ApiClient.get(ProductsRoutes.GET_PRODUCTS, params, { signal });
  }
  static getProductsProperties(signal) {
    return ApiClient.get(ProductsRoutes.GET_PRODUCTS_PROPERTIES, {}, { signal });
  }
  static getProductCreationData(params) {
    return ApiClient.get(ProductsRoutes.GET_PROD_CREATION_DATA, params);
  }
  static addProduct(params) {
    return ApiClient.put(ProductsRoutes.ADD_PRODUCT, params);
  }
  static deleteProduct(product_id) {
    return ApiClient.delete(ProductsRoutes.DELETE_PRODUCT(product_id));
  }
  static getProducers(params, signal) {
    return ApiClient.get(ProductsRoutes.GET_PRODUCERS, params, { signal });
  }
  static getCategories(params, signal) {
    return ApiClient.get(ProductsRoutes.GET_CATEGORIES, params, { signal });
  }
  static productDetails(id, params) {
    return ApiClient.get(ProductsRoutes.PRODUCT_DETAILS(id), params);
  }
  static productOrders(id, params) {
    return ApiClient.get(ProductsRoutes.PRODUCT_ORDERS(id), params);
  }
  static productPricings(id, params) {
    return ApiClient.get(ProductsRoutes.PRODUCT_PRICINGS(id), params);
  }
  static exportAllProducts(params) {
    return ApiClient.get(ProductsRoutes.EXPORT_ALL_PRODUCTS, params);
  }
  static importEditedProducts(data) {
    return ApiClient.post(ProductsRoutes.IMPORT_EDITED_PRODUCTS, data, formDataHeaders);
  }
  static importNewProducts(data) {
    return ApiClient.post(ProductsRoutes.IMPORT_NEW_PRODUCTS, data, formDataHeaders);
  }
  static replaceProduct(data) {
    return ApiClient.post(ProductsRoutes.REPLACE_PRODUCT, data);
  }
  static editProductEan(data) {
    return ApiClient.put(ProductsRoutes.EDIT_PRODUCT_EAN, data);
  }
}

export class OfferCatalogApi {
  static getData(data, signal) {
    return ApiClient.get(OfferCatalogRoutes.GET_DATA, data, { signal });
  }
  static selectPrice(data) {
    return ApiClient.post(OfferCatalogRoutes.SELECT_PRICE, data);
  }
  static resolveOrderData(params, signal) {
    return ApiClient.get(OfferCatalogRoutes.RESOLVE_ORDER_DATA, params, { signal });
  }
  static changeQuantity(data) {
    return ApiClient.post(OfferCatalogRoutes.CHANGE_QUANTITY, data);
  }
  static addPrice(data) {
    return ApiClient.post(OfferCatalogRoutes.ADD_PRICE, data);
  }
  static addVolume(data) {
    return ApiClient.post(OfferCatalogRoutes.ADD_PRODUCT_MONTH_VOLUME, data);
  }
  static createOrder(data) {
    return ApiClient.post(OfferCatalogRoutes.CREATE_ORDER, data);
  }
  static removeAllSelects(data) {
    return ApiClient.post(OfferCatalogRoutes.REMOVE_ALL_SELECTS, data);
  }
  static getSummary(params, signal) {
    return ApiClient.get(OfferCatalogRoutes.GET_SUMMARY, params, { signal });
  }
  static optimizeOfferCatalog(data) {
    return ApiClient.post(OfferCatalogRoutes.OPTIMIZE_OFFER_CATALOG, data);
  }
  static setPreferredSupplier(data) {
    return ApiClient.post(OfferCatalogRoutes.SET_PREFERRED_SUPPLIER, data);
  }
  static deleteProduct(data) {
    return ApiClient.delete(OfferCatalogRoutes.DELETE_PRODUCT, data);
  }
  static deleteSinglePrice(data) {
    return ApiClient.post(OfferCatalogRoutes.DELETE_PRICE, data);
  }
  static selectPreferred(data) {
    return ApiClient.post(OfferCatalogRoutes.SELECT_PREFERRED, data);
  }
  static exportCatalogCSV(data) {
    return ApiClient.get(OfferCatalogRoutes.EXPORT_CATALOG, data);
  }
  static exportCatalogXLSX(data) {
    return ApiClient.get(OfferCatalogRoutes.EXPORT_CATALOG, data);
  }
  static exportAllProductsForCatalog(data) {
    return ApiClient.get(OfferCatalogRoutes.EXPORT_ALL_PRODUCTS_FOR_CATALOG, data);
  }
  static exportProductsVolume(data) {
    return ApiClient.get(OfferCatalogRoutes.EXPORT_PRODUCTS_VOLUME, data);
  }
  static importProductsVolume(data) {
    return ApiClient.post(OfferCatalogRoutes.IMPORT_PRODUCTS_VOLUME, data, formDataHeaders);
  }
  static importCatalogPrices(data) {
    return ApiClient.post(OfferCatalogRoutes.IMPORT_CATALOG_PRICES, data, formDataHeaders);
  }
  static getProducts(params, signal) {
    return ApiClient.get(OfferCatalogRoutes.GET_PRODUCTS, params, { signal });
  }
  static setPreferredSuppliers(data) {
    return ApiClient.post(OfferCatalogRoutes.SET_PREFERRED_SUPPLIERS, data);
  }
  static getOfferCatalogProductsWithoutPrice(params) {
    return ApiClient.get(OfferCatalogRoutes.GET_OFFER_CATALOG_PRODUCTS_WITHOUT_PRICE, params);
  }
  static editPriceComment(data) {
    return ApiClient.post(OfferCatalogRoutes.EDIT_PRICE_COMMENT, data);
  }
  static deletePriceComment(priceId) {
    return ApiClient.delete(OfferCatalogRoutes.DELETE_PRICE_COMMENT(priceId));
  }
  static getSuppliersParticipation(params) {
    return ApiClient.get(OfferCatalogRoutes.GET_SUPPLIERS_PARTICIPATION, params);
  }
  static getCatalogSavingsForVolumes(params) {
    return ApiClient.get(OfferCatalogRoutes.GET_CATALOG_SAVINGS_FOR_VOLUMES, params);
  }
  static exportCatalogSuppliersOffers(params) {
    return ApiClient.get(OfferCatalogRoutes.EXPORT_CATALOG_SUPPLIERS_OFFERS, params);
  }
  static getAnalyticsSummaryForOfferCatalog(selectedCompany) {
    return ApiClient.get(OfferCatalogRoutes.GET_ANALYTICS_SUMMARY_FOR_OFFER_CATALOG, { company_id: selectedCompany });
  }
  static getVolumesForProduct(params) {
    return ApiClient.get(OfferCatalogRoutes.GET_VOLUMES_FOR_PRODUCT, params);
  }
  static getVolumesHistoryForProduct(params) {
    return ApiClient.get(OfferCatalogRoutes.GET_VOLUMES_HISTORY_FOR_PRODUCT, params);
  }
  static setAvgVolumesForCatalog(params) {
    return ApiClient.post(OfferCatalogRoutes.SET_AVG_VOLUMES_FOR_CATALOG, params);
  }
  static setAvgVolumesWithRangeForCatalog(params) {
    return ApiClient.post(OfferCatalogRoutes.SET_AVG_VOLUMES_WITH_RANGE_FOR_CATALOG, params);
  }
  static getCatalogPricesHistory(params, signal) {
    return ApiClient.get(OfferCatalogRoutes.GET_CATALOG_PRICES_HISTORY, params, { signal });
  }
  static exportCatalogPricesHistory(data) {
    return ApiClient.get(OfferCatalogRoutes.EXPORT_CATALOG_PRICES_HISTORY, data);
  }
  static editPriceOffer(data) {
    return ApiClient.post(OfferCatalogRoutes.EDIT_OFFER_PRICE, data);
  }
}

export class ValuationsApi {
  static getValuations(params, signal) {
    return ApiClient.get(ValuationsRoutes.GET_VALUATIONS, params, { signal });
  }
  static getValuationProducts(data, signal) {
    return ApiClient.get(ValuationsRoutes.GET_VALUATION_PRODUCTS, data, {
      signal
    });
  }
  static getSimpleValuationProducts(data) {
    return ApiClient.get(ValuationsRoutes.GET_VALUATION_PRODUCTS_SIMPLE, data);
  }
  static createOrder(data) {
    return ApiClient.post(ValuationsRoutes.CREATE_ORDER, data);
  }
  static saveOrderDataDistributor(data) {
    return ApiClient.post(ValuationsRoutes.SAVE_ORDER_DIST, data);
  }
  static saveOrderDataProducts(uid) {
    return ApiClient.post(ValuationsRoutes.SAVE_ORDER_PRODUCTS, { uid });
  }
  static resolveOrderData(data) {
    return ApiClient.get(ValuationsRoutes.RESOLVE_ORDER, data);
  }
  static cancelValuation(uid) {
    return ApiClient.delete(ValuationsRoutes.CANCEL_VALUATION(uid));
  }
  static changeSelectedProductDistributor(data) {
    return ApiClient.post(ValuationsRoutes.CHANGE_SELECTED_PRODUCT_DIST, data);
  }
  static changeSupplierOffer(data) {
    return ApiClient.post(ValuationsRoutes.CHANGE_SUPPLIER_OFFER, data);
  }
  static deleteSelection(data) {
    return ApiClient.post(ValuationsRoutes.DELETE_OFFER_SELECTION, data);
  }
  static selectAllInColumn(data) {
    return ApiClient.post(ValuationsRoutes.SELECT_ALL_IN_COLUMN, data);
  }
  static unselectAllInColumn(data) {
    return ApiClient.post(ValuationsRoutes.UNSELECT_ALL_IN_COLUMN, data);
  }
  static selectSubstitute(data) {
    return ApiClient.post(ValuationsRoutes.SELECT_SUBSTITUTE, data);
  }
  static createSupplierValuation(data) {
    return ApiClient.post(ValuationsRoutes.CREATE_SUPPLIER_VALUATION, data);
  }
  static optimizeSelect(data) {
    return ApiClient.get(ValuationsRoutes.OPTIMIZE_SELECT, data);
  }
  static hideColumns(data) {
    return ApiClient.post(ValuationsRoutes.HIDE_COLUMNS, data);
  }
  static showColumns(data) {
    return ApiClient.post(ValuationsRoutes.SHOW_COLUMNS, data);
  }
  static changeProductQuantity(data) {
    return ApiClient.post(ValuationsRoutes.CHANGE_ORDER_QUANTITY, data);
  }
  static getPendingStatus(data) {
    return ApiClient.get(ValuationsRoutes.GET_PENDING_STATUS, data);
  }
}

export class OrdersApi {
  static getOrders(params, signal) {
    return ApiClient.get(OrdersRoutes.GET_ORDERS, params, { signal });
  }
  static getProducts(params, signal) {
    return ApiClient.get(OrdersRoutes.GET_PRODUCTS, params, { signal });
  }
  static cancelOrder(params) {
    return ApiClient.post(OrdersRoutes.CANCEL_ORDER, params);
  }
  static changeProductsStatus(data) {
    return ApiClient.post(OrdersRoutes.CHANGE_PRODUCTS_STATUS, data);
  }
  static addOrderToWarehouse(data) {
    return ApiClient.post(OrdersRoutes.ADD_ORDER_TO_WAREHOUSE, data);
  }
  static addProductComment(data) {
    return ApiClient.post(OrdersRoutes.ADD_PRODUCT_COMMENT, data);
  }
  static uploadUserAttachment(data) {
    return ApiClient.post(OrdersRoutes.UPLOAD_USER_ATTACHMENT, data, formDataHeaders);
  }
  static deleteUserAttachment(data) {
    return ApiClient.post(OrdersRoutes.DELETE_USER_ATTACHMENT, data);
  }
  static addOrderMessage(data) {
    return ApiClient.post(OrdersRoutes.ADD_MESSAGE, data);
  }
  static cancelOrderLink(data) {
    return ApiClient.post(OrdersRoutes.CANCEL_ORDER_LINK, data);
  }
  static sentToApproval(uid) {
    return ApiClient.post(OrdersRoutes.SEND_ORDER_APPROVAL, { uid });
  }
  static partialReceiveProducts(data) {
    return ApiClient.post(OrdersRoutes.PARTIAL_RECEIVE_PRODUCTS, data);
  }
  static refreshOrdersStatus(data) {
    return ApiClient.post(OrdersRoutes.REFRESH_ORDERS_STATUS, { orders_uids: data });
  }
  static sendSystemMessage(data) {
    return ApiClient.post(OrdersRoutes.SEND_SYSTEM_MESSAGE, data);
  }
  static sendSupplierSystemMessage(data) {
    return ApiClient.post(OrdersRoutes.SEND_SUPPLIER_SYSTEM_MESSAGE, data);
  }
}

export class SupplierApi {
  static getCollections(params) {
    return ApiClient.get(SupplierRoutes.GET_COLLECTIONS, params);
  }
  static getForm(params) {
    return ApiClient.get(SupplierRoutes.GET_FORM, params);
  }
  static savePrice(data, signal) {
    return ApiClient.post(SupplierRoutes.SAVE_PRICE, data, { signal });
  }
  static removeOfferOrReplacement(data, signal) {
    return ApiClient.post(SupplierRoutes.REMOVE_OFFER_OR_REPLACEMENT, data, { signal });
  }
  static saveFormData(params) {
    return ApiClient.put(SupplierRoutes.SAVE_FORM_DATA, params);
  }
  static getOrder(params) {
    return ApiClient.get(SupplierRoutes.GET_ORDER, params);
  }
  static saveOrderLink(data) {
    return ApiClient.post(SupplierRoutes.SAVE_ORDER_LINK, data);
  }
  static updateShippingPrice(data) {
    return ApiClient.post(SupplierRoutes.UPDATE_SHIPPING_PRICE, data);
  }
  static updateOrder(data) {
    return ApiClient.post(SupplierRoutes.UPDATE_ORDER, data);
  }
  static finalizeOrder(data) {
    return ApiClient.post(SupplierRoutes.FINALIZE_ORDER, data, formDataHeaders);
  }
  static deleteSupplierInvoice(data) {
    return ApiClient.post(SupplierRoutes.DELETE_SUPPLIER_INVOICE, data);
  }
  static sendValuation(data) {
    return ApiClient.post(SupplierRoutes.SEND_VALUATION, data);
  }
  static getValuations(data, signal) {
    return ApiClient.get(SupplierRoutes.GET_VALUATIONS, data, { signal });
  }
  static getOrders(data, signal) {
    return ApiClient.get(SupplierRoutes.GET_ORDERS, data, { signal });
  }
  static cancelOrder(data) {
    return ApiClient.post(SupplierRoutes.CANCEL_ORDER, data);
  }
  static addShippingMethod(data) {
    return ApiClient.post(SupplierRoutes.ADD_SHIPPING_METHOD, data);
  }
  static deleteShippingMethod(id, uid) {
    return ApiClient.post(SupplierRoutes.DELETE_SHIPPING_METHOD, { id, uid });
  }
  static acceptProductQuantity(data) {
    return ApiClient.post(SupplierRoutes.ACCEPT_NEW_QUANTITY, data);
  }

  static acceptNewPrice(data) {
    return ApiClient.post(SupplierRoutes.ACCEPT_NEW_PRICE, data);
  }
  static getProducts(params, signal) {
    return ApiClient.get(SupplierRoutes.GET_PRODUCTS, params, {
      signal
    });
  }
  static addProductToCollection(data) {
    return ApiClient.post(SupplierRoutes.ADD_TO_COLLECTION, data);
  }
  static removeProductFromCollection(data) {
    return ApiClient.post(SupplierRoutes.REMOVE_FROM_COLLECTION, data);
  }
  static getClients(params, signal) {
    return ApiClient.get(SupplierRoutes.GET_CLIENTS, params, { signal });
  }
  static changePrice(data) {
    return ApiClient.post(SupplierRoutes.CHANGE_PRICE, data);
  }
  static uploadSupplierAttachment(data) {
    return ApiClient.post(SupplierRoutes.UPLOAD_SUPPLIER_ATTACHMENT, data, formDataHeaders);
  }
  static editSupplierInvoice(data) {
    return ApiClient.post(SupplierRoutes.EDIT_SUPPLIER_INVOICE, data, formDataHeaders);
  }
  static uploadSupplierInvoice(data) {
    return ApiClient.post(SupplierRoutes.UPLOAD_SUPPLIER_INVOICE, data, formDataHeaders);
  }
  static deleteSupplierAttachment(data) {
    return ApiClient.post(SupplierRoutes.DELETE_SUPPLIER_ATTACHMENT, data);
  }
  static addOrderMessage(data) {
    return ApiClient.post(SupplierRoutes.ADD_MESSAGE, data);
  }
  static orderApprovalData(token) {
    return ApiClient.get(SupplierRoutes.ORDER_APPROVAL_DATA, { token });
  }
  static orderApproval(data) {
    return ApiClient.post(SupplierRoutes.ORDER_APPROVAL, data);
  }
  static downloadInvoice(params) {
    return ApiClient.get(SupplierRoutes.DOWNLOAD_INVOICE, params, responseTypeBlob);
  }
  static changeSupplierOffersVisibility(data) {
    return ApiClient.post(SupplierRoutes.HIDE_SUPPLIER_OFFERS, data);
  }
  static sendOrderedProductsBySupplier(data) {
    return ApiClient.post(SupplierRoutes.SEND_ORDERED_PRODUCTS_BY_SUPPLIER, data);
  }
  static sendPartialProducts(data) {
    return ApiClient.post(SupplierRoutes.SEND_PARTIAL_PRODUCTS, data);
  }
  static exportSuborderProducts(data) {
    return ApiClient.get(SupplierRoutes.EXPORT_SUBORDER_PRODUCTS, data);
  }
}

export class DistributorApi {
  static getOrder(params, signal) {
    return ApiClient.get(DistributorRoutes.GET_ORDER, params, { signal });
  }
}

export class AdminApi {
  static UserProductToDb(data) {
    return ApiClient.post(AdminRoutes.USER_TO_DB_PRODUCT, data);
  }
  static deleteDistributor(id) {
    return ApiClient.delete(AdminRoutes.DELETE_DISTRIBUTOR(id));
  }
  static saveSupplier(data) {
    return ApiClient.put(AdminRoutes.SAVE_SUPPLIER, data);
  }
  static getCategories() {
    return ApiClient.get(AdminRoutes.GET_CATEGORIES);
  }
  static getUsers(data, signal) {
    return ApiClient.get(AdminRoutes.GET_USERS, data, { signal });
  }
  static getUserRoles(data) {
    return ApiClient.get(AdminRoutes.GET_USER_ROLES, data);
  }
  static addUser(data) {
    return ApiClient.post(AdminRoutes.ADD_USER, data);
  }
  static putUserData(id, data) {
    return ApiClient.put(AdminRoutes.USER_DATA(id), data);
  }
  static getUser(id, params) {
    return ApiClient.get(AdminRoutes.USER_DATA(id), params);
  }
  static addCategory(data) {
    return ApiClient.post(AdminRoutes.ADD_CATEGORY, data);
  }
  static saveCategory(data) {
    return ApiClient.post(AdminRoutes.SAVE_CATEGORY, data);
  }
  static addUserProductToDb(id) {
    return ApiClient.post(AdminRoutes.USER_PRODUCT_TO_DB, { id });
  }
  static extendValuationDate(uid, extend_to) {
    return ApiClient.put(AdminRoutes.VALUATION_EXTEND, { uid, extend_to });
  }
  static addProducer(name) {
    return ApiClient.put(AdminRoutes.ADD_PRODUCER, { name });
  }
  static deleteSupplierUserConnection(data) {
    return ApiClient.post(AdminRoutes.DELETE_SUPPLIER_USER_CONNECTION, data);
  }
  static moveProducerProducts(data) {
    return ApiClient.post(AdminRoutes.MOVE_PRODUCER_PRODUCTS, data);
  }
  static deleteProducer(id) {
    return ApiClient.delete(AdminRoutes.DELETE_PRODUCER(id));
  }
  static extendUserValuationDate(uid, extend_to) {
    return ApiClient.put(AdminRoutes.USER_VALUATION_EXTEND, { uid, extend_to });
  }
  static moveValuation(data) {
    return ApiClient.post(AdminRoutes.MOVE_VALUATION, data);
  }
  static remindDistributorValuation(uid) {
    return ApiClient.post(AdminRoutes.REMIND_DISTRIBUTOR_VALUATION, { uid });
  }
  static getCompanies(params, signal) {
    return ApiClient.get(AdminRoutes.GET_COMPANIES, params, { signal });
  }
  static getCompaniesAsTree(params, signal) {
    return ApiClient.get(AdminRoutes.GET_COMPANIES_AS_TREE, params, { signal });
  }

  static moveCompany(data, signal) {
    return ApiClient.post(AdminRoutes.MOVE_COMPANY, data, { signal });
  }
  static getCompanyDetails(id, signal) {
    return ApiClient.get(AdminRoutes.COMPANY_DETAILS(id), { signal });
  }
  static setCompanyDetails(id, data) {
    return ApiClient.post(AdminRoutes.COMPANY_DETAILS(id), data);
  }
  static getCompanyMembers(params, signal) {
    return ApiClient.get(AdminRoutes.GET_COMPANY_MEMBERS, params, signal);
  }
  static updateShippingDetails(data) {
    return ApiClient.post(AdminRoutes.ADD_SHIPPING_ADDRESS, data);
  }
  static deleteShippingDetails(id) {
    return ApiClient.delete(AdminRoutes.DELETE_SHIPPING_ADDRESS(id));
  }
  static deleteCompanyMember(data) {
    return ApiClient.post(AdminRoutes.DELETE_COMPANY_MEMBER, data);
  }
  static inviteUserToCompany(data, signal) {
    return ApiClient.post(AdminRoutes.INVITE_USER_TO_COMPANY, data, { signal });
  }
  static cancelInvitation(data) {
    return ApiClient.post(AdminRoutes.REMOVE_USER_INVITE_TO_COMPANY, data);
  }
  static getUserToken(user_id) {
    return ApiClient.get(AdminRoutes.GET_USER_TOKEN, { user_id });
  }
  static deleteCategory(id) {
    return ApiClient.post(AdminRoutes.DELETE_CATEGORY, { id });
  }
  static moveCategoryProducts(data) {
    return ApiClient.post(AdminRoutes.MOVE_CATEGORY_PRODUCTS, data);
  }
  static hideCategory(category_id) {
    return ApiClient.post(AdminRoutes.HIDE_CATEGORY, { category_id });
  }
  static showCategory(category_id) {
    return ApiClient.post(AdminRoutes.SHOW_CATEGORY, { category_id });
  }
  static deactivateUser(user_id) {
    return ApiClient.post(AdminRoutes.DEACTIVATE_USER, { user_id });
  }
  static activateUser(user_id) {
    return ApiClient.post(AdminRoutes.ACTIVATE_USER, { user_id });
  }
  static editListProduct(data) {
    return ApiClient.post(AdminRoutes.EDIT_PRODUCT_PRICE, data);
  }
  static getOrderApprovals(params, signal) {
    return ApiClient.get(AdminRoutes.GET_ORDER_APPROVALS, params, { signal });
  }
  static changeSupplierEmail(data) {
    return ApiClient.post(AdminRoutes.CHANGE_SUPPLIER_EMAIL, data);
  }
  static deleteCompany(id) {
    return ApiClient.post(AdminRoutes.DELETE_COMPANY, { id });
  }
  static activateCompany(id) {
    return ApiClient.post(AdminRoutes.ACTIVATE_COMPANY, { id });
  }
}

export class WarehouseApi {
  static getWarehouseData(data) {
    return ApiClient.get(WarehouseRoutes.GET_WAREHOUSE_DATA, data);
  }
  static addProduct(data) {
    return ApiClient.put(WarehouseRoutes.ADD_PRODUCT, data);
  }
  static changeProductState(data) {
    return ApiClient.post(WarehouseRoutes.CHANGE_PRODUCT_STATE, data);
  }
  static getProductsHistory(params) {
    return ApiClient.get(WarehouseRoutes.GET_PRODUCTS_HISTORY, params);
  }
  //
  //   new warehouse
  //
  static getDepartments(params) {
    return ApiClient.get(WarehouseRoutes.GET_DEPARTMENTS, params);
  }
  static addOrUpdateDepartment(data) {
    return ApiClient.put(WarehouseRoutes.ADD_OR_UPDATE_DEPARTMENT, data);
  }
  static deleteDepartment(id) {
    return ApiClient.delete(WarehouseRoutes.DELETE_DEPARTMENT(id));
  }
  static getProducts(params) {
    return ApiClient.get(WarehouseRoutes.GET_PRODUCTS, params);
  }
  static addOrUpdateProduct(data) {
    return ApiClient.put(WarehouseRoutes.ADD_OR_UPDATE_PRODUCT, data);
  }
  static deleteProduct(id) {
    return ApiClient.delete(WarehouseRoutes.DELETE_PRODUCT(id));
  }
  static addManualSupply(data) {
    return ApiClient.post(WarehouseRoutes.ADD_MANUAL_SUPPLY, data);
  }
  static addRelease(data) {
    return ApiClient.post(WarehouseRoutes.ADD_RELEASE, data);
  }
  static addOrderSupply(data) {
    return ApiClient.post(WarehouseRoutes.ADD_ORDER_SUPPLY, data);
  }
  static getProductSupplies(params, signal) {
    return ApiClient.get(WarehouseRoutes.GET_PRODUCT_SUPPLIES, params, { signal });
  }
  static getProductReleases(params, signal) {
    return ApiClient.get(WarehouseRoutes.GET_PRODUCT_RELEASES, params, { signal });
  }
  static getSummary(params) {
    return ApiClient.get(WarehouseRoutes.GET_SUMMARY, params);
  }
  static exportWarehouseProducts(params) {
    return ApiClient.get(WarehouseRoutes.EXPORT_WAREHOUSE_PRODUCTS, params);
  }
  static importWarehouseProducts(data) {
    return ApiClient.post(WarehouseRoutes.IMPORT_WAREHOUSE_PRODUCTS, data, formDataHeaders);
  }
  static exportWarehouseHistory(params) {
    return ApiClient.get(WarehouseRoutes.EXPORT_WAREHOUSE_HISTORY, params);
  }
  static getWarehouseHistory(params) {
    return ApiClient.get(WarehouseRoutes.GET_WAREHOUSE_HISTORY, params);
  }
  static getProductsCategories(params) {
    return ApiClient.get(WarehouseRoutes.GET_PRODUCTS_CATEGORIES, params);
  }
  static addOrUpdateProductComment(data) {
    return ApiClient.put(WarehouseRoutes.ADD_OR_UPDATE_PRODUCT_COMMENT, data);
  }
  static recountProductsData(data) {
    return ApiClient.post(WarehouseRoutes.RECOUNT_PRODUCTS_DATA, data);
  }
  static updateSupplyPrice(data) {
    return ApiClient.post(WarehouseRoutes.UPDATE_SUPPLY_PRICE, data);
  }
}

export class StatisticsApi {
  static get() {
    return ApiClient.get(StatisticsRoutes.GET_STATISTICS);
  }
  static getProductsData(params, signal) {
    return ApiClient.get(StatisticsRoutes.PRODUCTS_DATA, params, { signal });
  }
  static getExpensesMonthly(params) {
    return ApiClient.get(StatisticsRoutes.GET_EXPENSES_MONTHLY, params);
  }
  static getExpensesMonthlyByCompanies(params) {
    return ApiClient.get(StatisticsRoutes.GET_EXPENSES_MONTHLY_BY_COMPANIES, params);
  }
  static getSavingsMonthly(params) {
    return ApiClient.get(StatisticsRoutes.GET_SAVINGS_MONTHLY, params);
  }
  static getSavingsMonthlyByCompanies(params) {
    return ApiClient.get(StatisticsRoutes.GET_SAVINGS_MONTHLY_BY_COMPANIES, params);
  }
  static getProductsRealisationBySupplier(params) {
    return ApiClient.get(StatisticsRoutes.GET_PRODUCTS_REALISATION_BY_SUPPLIER, params);
  }
  static getProductOrders(params) {
    return ApiClient.get(StatisticsRoutes.GET_PRODUCT_ORDERS(params.id), params);
  }
  static getAvgActionsTimesByOrder(params) {
    return ApiClient.get(StatisticsRoutes.GET_AVG_ACTIONS_TIMES_BY_ORDER, params);
  }
  static getExpensesByCategory(params) {
    return ApiClient.get(StatisticsRoutes.GET_EXPENSES_BY_CATEGORY, params);
  }
  static getActionsTimesForOrder(params) {
    return ApiClient.get(StatisticsRoutes.GET_ACTIONS_TIMES_FOR_ORDER, params);
  }
  static exportExpensesMonthly(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_EXPENSES_MONTHLY, params);
  }
  static exportExpensesByCategory(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_EXPENSES_BY_CATEGORY, params);
  }
  static exportProductsRealisationBySupplier(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_PRODUCTS_REALISATION_BY_SUPPLIER, params);
  }
  static exportSavingsData(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_SAVINGS_DATA, params);
  }
  static exportAvgActionsTimesByOrder(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_AVG_ACTIONS_TIMES_BY_ORDER, params);
  }
  static exportActionsTimesByOrder(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_ACTIONS_TIMES_BY_ORDER, params);
  }
  static exportOrdersData(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_ORDERS_DATA, params);
  }
  static getProductsRealisationBySupplierMonthly(params) {
    return ApiClient.get(StatisticsRoutes.GET_PRODUCTS_REALISATION_BY_SUPPLIER_MONTHLY, params);
  }
  static getProductsRealisationBySupplierForOrderUid(order_uid) {
    return ApiClient.get(StatisticsRoutes.GET_PRODUCTS_REALISATION_BY_SUPPLIER_FOR_ORDER_UID, { order_uid });
  }
  static getTopProducts(params) {
    return ApiClient.get(StatisticsRoutes.GET_TOP_PRODUCTS, params);
  }
  static exportTopProducts(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_TOP_PRODUCTS, params);
  }
  static getOrdersDataForAdmin(params) {
    return ApiClient.get(StatisticsRoutes.GET_ORDERS_DATA_FOR_ADMIN, params);
  }
  static getPricesChangesStatistics(params) {
    return ApiClient.get(StatisticsRoutes.GET_PRICES_CHANGES_STATISTICS, params);
  }
  static exportPricesChangesStatistics(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_PRICES_CHANGES_STATISTICS, params);
  }
  static getSalePerProductStatistics(params) {
    return ApiClient.get(StatisticsRoutes.GET_SALE_PER_PRODUCT_STATISTICS, params);
  }
  static exportSalePerProductStatistics(params) {
    return ApiClient.get(StatisticsRoutes.EXPORT_SALE_PER_PRODUCT_STATISTICS, params);
  }
  static generateReport(params) {
    return ApiClient.get(StatisticsRoutes.GENERATE_REPORT, params, responseTypeBlob);
  }
}

export class PermissionsApi {
  static grantPermission(data) {
    return ApiClient.post(PermissionsRoutes.GRANT_PERMISSION, data);
  }
  static revokePermission(data) {
    return ApiClient.post(PermissionsRoutes.REVOKE_PERMISSION, data);
  }
}

export class LandingApi {
  static registerToNewsletter(params) {
    return ApiClient.get(LandingRoutes.SEND_EMAIL, params);
  }
}

export class ServicesApi {
  static getServicersList(params) {
    return ApiClient.get(ServicesRoutes.GET_SERVICERS_LIST, params);
  }
  static getServicesList(params) {
    return ApiClient.get(ServicesRoutes.GET_SERVICES_LIST, params);
  }
  static downloadEventFile(params) {
    return ApiClient.get(ServicesRoutes.DOWNLOAD_EVENT_FILE, params, responseTypeBlob);
  }
  static downloadServiceFile(params) {
    return ApiClient.get(ServicesRoutes.DOWNLOAD_SERVICE_FILE, params, responseTypeBlob);
  }
  static getEventsList(params) {
    return ApiClient.get(ServicesRoutes.GET_EVENTS_LIST, params);
  }
  static getService(service_id, signal) {
    return ApiClient.get(ServicesRoutes.GET_SERVICE, { service_id }, { signal });
  }
  static addServicer(params) {
    return ApiClient.post(ServicesRoutes.ADD_SERVICER, params);
  }
  static addService(params) {
    return ApiClient.post(ServicesRoutes.ADD_SERVICE, params, formDataHeaders);
  }
  static addEvent(params) {
    return ApiClient.post(ServicesRoutes.ADD_EVENT, params, formDataHeaders);
  }
  static editServicer(params) {
    return ApiClient.post(ServicesRoutes.EDIT_SERVICER, params);
  }
  static editService(params) {
    return ApiClient.post(ServicesRoutes.EDIT_SERVICE, params, formDataHeaders);
  }
  static editEvent(params) {
    return ApiClient.post(ServicesRoutes.EDIT_EVENT, params);
  }
  static deleteServicer(servicer_id) {
    return ApiClient.delete(ServicesRoutes.DELETE_SERVICER, { servicer_id });
  }
  static deleteEvent(event_id) {
    return ApiClient.delete(ServicesRoutes.DELETE_EVENT, { event_id });
  }
  static uploadEventFile(params) {
    return ApiClient.post(ServicesRoutes.UPLOAD_EVENT_FILE, params, formDataHeaders);
  }
  static uploadServiceFile(params) {
    return ApiClient.post(ServicesRoutes.UPLOAD_SERVICE_FILE, params, formDataHeaders);
  }
  static deleteService(service_id) {
    return ApiClient.delete(ServicesRoutes.DELETE_SERVICE, { service_id });
  }
  static deleteEventFile(params) {
    return ApiClient.delete(ServicesRoutes.DELETE_EVENT_FILE, params);
  }
  static deleteServiceFile(params) {
    return ApiClient.delete(ServicesRoutes.DELETE_SERVICE_FILE, params);
  }
  static getServiceHistory(params, signal) {
    return ApiClient.get(ServicesRoutes.GET_SERVICE_HISTORY, params, { signal });
  }
}

export class WholesalersApi {
  static getWholesalers(params, signal) {
    return ApiClient.get(WholesalersRoutes.GET_WHOLESALERS, params, { signal });
  }
  static addOrUpdateWholesaler(data) {
    return ApiClient.post(WholesalersRoutes.ADD_OR_UPDATE_WHOLESALER, data, formDataHeaders);
  }
  static deleteWholesaler(id) {
    return ApiClient.delete(WholesalersRoutes.DELETE_WHOLESALER(id));
  }
  static getWholesalerDetails(params, signal) {
    return ApiClient.get(WholesalersRoutes.GET_WHOLESALER_DETAILS, { wholesaler_id: params }, { signal });
  }
  static getProducts(params, signal) {
    return ApiClient.get(WholesalersRoutes.GET_PRODUCTS, params, { signal });
  }
  static addOrUpdateProduct(data) {
    return ApiClient.put(WholesalersRoutes.ADD_OR_UPDATE_PRODUCT, data);
  }
  static deleteWholesalerProduct(id) {
    return ApiClient.delete(WholesalersRoutes.DELETE_WHOLESALER_PRODUCT(id));
  }
  static setWholesalerToSupplier(data) {
    return ApiClient.post(WholesalersRoutes.SET_WHOLESALER_TO_SUPPLIER, data);
  }
  static dissociateWholesalerFromSupplier(supplierId) {
    return ApiClient.delete(WholesalersRoutes.DISSOCIATE_WHOLESALER_FROM_SUPPLIER(supplierId));
  }
  static autoSetWholesalersToSuppliers(supplier_ids) {
    return ApiClient.post(WholesalersRoutes.AUTO_SET_WHOLESALERS_TO_SUPPLIERS, { supplier_ids });
  }
  static getWholesalerDistributors(params, signal) {
    return ApiClient.get(WholesalersRoutes.GET_WHOLESALER_DISTRIBUTORS, params, { signal });
  }
  static exportWholesalersProductsTemplateFile(data) {
    return ApiClient.get(WholesalersRoutes.EXPORT_WHOLESALERS_PRODUCTS_TEMPLATE_FILE, data);
  }
  static exportWholesalerProducts(data) {
    return ApiClient.get(WholesalersRoutes.EXPORT_WHOLESALER_PRODUCTS, data);
  }
  static importWholesalerProducts(data) {
    return ApiClient.post(WholesalersRoutes.IMPORT_WHOLESALER_PRODUCTS, data, formDataHeaders);
  }
}

export class TagsApi {
  static getTags(params, signal) {
    return ApiClient.get(TagsRoutes.GET_TAGS, params, { signal });
  }
  static addTag(params) {
    return ApiClient.post(TagsRoutes.ADD_TAG, params);
  }
  static editTag(params) {
    return ApiClient.put(TagsRoutes.EDIT_TAG, params);
  }
  static deleteTag(id) {
    return ApiClient.delete(TagsRoutes.DELETE_TAG, { id });
  }
  static addProductTag(params) {
    return ApiClient.post(TagsRoutes.ADD_PRODUCT_TAG, params);
  }
  static deleteProductTag(params) {
    return ApiClient.delete(TagsRoutes.DELETE_PRODUCT_TAG, params);
  }
  static getTagsHistory(params, signal) {
    return ApiClient.get(TagsRoutes.GET_TAGS_HISTORY, params, { signal });
  }
}

export class ReplacementsApi {
  static getGroups(params, signal) {
    return ApiClient.get(ReplacementsRoutes.GET_GROUPS, params, { signal });
  }
  static addGroup(params) {
    return ApiClient.post(ReplacementsRoutes.ADD_GROUP, params);
  }
  static editGroup(params) {
    return ApiClient.post(ReplacementsRoutes.EDIT_GROUP, params);
  }
  static deleteGroup(group_id) {
    return ApiClient.delete(ReplacementsRoutes.DELETE_GROUP, { group_id });
  }
  static removeGroupProduct(product_id) {
    return ApiClient.delete(ReplacementsRoutes.DELETE_GROUP_PRODUCT, { product_id });
  }
  static addGroupProducts(params) {
    return ApiClient.post(ReplacementsRoutes.ADD_GROUP_PRODUCTS, params);
  }
  static moveGroupProduct(params) {
    return ApiClient.post(ReplacementsRoutes.MOVE_GROUP_PRODUCT, params);
  }
  static mergeGroups(params) {
    return ApiClient.post(ReplacementsRoutes.MERGE_GROUPS, params);
  }
  static getProductReplacements(product_id) {
    return ApiClient.get(ReplacementsRoutes.GET_PRODUCT_REPLACEMENTS, { product_id });
  }
}
