import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Accordion, Button, EmptyState, Loader, Modal, useIsAdmin, usePermissions } from 'components';
import CreateValuationForm from 'components/layout/CreateValuationForm';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi, ListsApi, OfferCatalogApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { handleNumeralWords } from 'src/utils/helpers';

import PendingProductsTable from '../PendingProductsTable';
import UserThumbnail from './components/UserThumbnail';

import style from './ProductsWithoutPrice.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsWithoutPrice = ({ selectedCompany, listUID, isCatalogView }) => {
  const isAdmin = useIsAdmin();

  const [valuationCreators, setValuationCreators] = useState([]);
  const [isContactVisible, setIsContactVisible] = useState(false);
  const [isCreatorsLoading, setIsCreatorsLoading] = useState(false);
  const [isValuationVisible, setIsValuationVisible] = useState(false);
  const [productsWithoutOffer, setProductsWithoutOffer] = useState([]);
  const [productsWithoutAssignedOffer, setProductsWithoutAssignedOffer] = useState([]);

  const version = useSelector((state) => state.cartAndCatalog.version);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);
  const [canCreateVerticalValuation] = usePermissions([userPermissions.valuations.create]);

  useEffect(() => {
    const getValuationCreators = async () => {
      const queryData = {
        ...(isAdmin && { company_id: selectedCompany }),
        whole_organization: 0
      };

      try {
        setIsCreatorsLoading(true);
        const { data } = await CompanyApi.getUsersValuationsCreate(queryData);
        setValuationCreators(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsCreatorsLoading(false);
      }
    };

    getValuationCreators();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const queryData = {
        ...(isAdmin && { company_id: selectedCompany }),
        list_uid: listUID
      };

      const Api = isCatalogView ? OfferCatalogApi : ListsApi;

      try {
        const { data } = await Api.getOfferCatalogProductsWithoutPrice(queryData);
        setProductsWithoutAssignedOffer(data.without_assigned_offer);
        setProductsWithoutOffer(data.without_offer);
      } catch (err) {
        notifyApiError(err);
      }
    };

    if (listUID) {
      getData();
    }
  }, [listUID, isDefinedView, selectedCompany, version]);

  if (
    (productsWithoutOffer?.length === 0 && productsWithoutAssignedOffer?.length === 0) ||
    (!isDefinedView && productsWithoutOffer.length === 0)
  ) {
    return null;
  }

  const header =
    productsWithoutOffer?.length > 0 ? (
      <div className={style.titleWrapper}>
        <div className={style.column}>
          <p className={style.description}>
            Na twojej liście znajdują się&nbsp;
            <strong>
              {productsWithoutOffer?.length}{' '}
              {handleNumeralWords(['produkt', 'produkty', 'produktów'], productsWithoutOffer?.length)} bez dodanych ofert.
            </strong>
          </p>
        </div>
        {canCreateVerticalValuation ? (
          <Button
            label={'Wyślij do wyceny'}
            onClick={() => setIsValuationVisible(true)}
          />
        ) : (
          <Button
            label={'Zgłoś do wyceny'}
            onClick={() => setIsContactVisible(true)}
          />
        )}
      </div>
    ) : (
      <div className={style.titleWrapper}>
        <div className={style.column}>
          <p className={style.description}>
            Na twojej liście znajdują się produkty bez przypisanych ofert <strong>({productsWithoutAssignedOffer.length})</strong>
            :
          </p>
        </div>
      </div>
    );

  return (
    <>
      <Accordion
        title={header}
        extraDeps={[productsWithoutAssignedOffer]}
        defaultWrapped
        openOnArrowClick
        section
        yellow
      >
        <div className={style.tableWrapper}>
          <PendingProductsTable
            products={productsWithoutOffer}
            productsWithoutAssignedOffer={productsWithoutAssignedOffer}
            isOrderView
            isDefinedView={isDefinedView}
          />
        </div>
      </Accordion>
      <Modal
        visible={isContactVisible}
        onClose={() => setIsContactVisible(false)}
        title={'Zgłoś do wyceny'}
      >
        {isCreatorsLoading ? (
          <Loader center />
        ) : (
          <>
            <p className={style.text}>Skontaktuj się z osobą odpowiedzialną za pozyskanie cen w oddziale.</p>
            <div className={classNames(shared.tableHeader, style.tableHeader)}>
              <p>Imię i nazwisko</p>
              <p>Stanowisko</p>
              <p>E-mail</p>
              <p>Telefon</p>
            </div>
            {valuationCreators.length > 0 ? (
              valuationCreators.map((user) => (
                <UserThumbnail
                  key={user.id}
                  {...user}
                />
              ))
            ) : (
              <EmptyState
                tiny
                type={'creators'}
              />
            )}
          </>
        )}
      </Modal>
      <Modal
        visible={isValuationVisible}
        onClose={() => setIsValuationVisible(false)}
        title={'Wyślij do wyceny'}
      >
        <CreateValuationForm
          products={productsWithoutOffer}
          listUID={listUID}
          selectedCompany={selectedCompany}
          onFinish={() => setIsValuationVisible(false)}
        />
      </Modal>
    </>
  );
};

export default ProductsWithoutPrice;
