import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import { atom } from 'jotai';

import { FilterByCompany, PageHeader, SeoHelmet, StickyPageHeader, useIsAdmin, useSearchParam } from 'components';
import TopProducts from 'components/layout/TopProducts';

import pages from 'src/dictionaries/pages.json';
import AdminSummary from 'src/features/Analytics/components/AdminSummary';
import OrdersSummary from 'src/features/Analytics/components/OrdersSummary';
import { getStartDate } from 'src/utils/helpers';
import query from 'src/utils/query';

import CompanySelect from './components/CompanySelect';
import DatePickerWrapper, { dateOptions } from './components/DatePickerWrapper';
import ExpensesByCategory from './components/ExpensesByCategory';
import ExpensesMonthly from './components/ExpensesMonthly';
import PricesChangeSummary from './components/PricesChangeSummary';
import ProductsRealisationBySupplier from './components/ProductsRealisationBySupplier';
import ProductsSaleSummary from './components/ProductsSaleSummary';
import ReportGenerator from './components/ReportGenerator';
import SavingsMonthly from './components/SavingsMonthly';
import TimeOfResponseOfSingleOrder from './components/TimeOfResponseOfSingleOrder';

import style from './Analytics.module.scss';

export const ordersAtom = atom(null);
export const suppliersCountAtom = atom(null);

const Analytics = (props) => {
  const params = query(props);
  const isAdmin = useIsAdmin();

  const formattedCompanies = useSelector((state) => state.auth.formattedCompanies);
  const [selectedCompanies, setSelectedCompanies] = useState(formattedCompanies || []);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year'));
  const [selectedValue, setSelectedValue] = useState(dateOptions[3]);
  const [endDate, setEndDate] = useState(dayjs());

  const selectedCompanyId = useSearchParam(params, 'company');
  const filters = useMemo(
    () => ({ startDate: dayjs(startDate).toISOString(), endDate: dayjs(endDate).toISOString(), selectedCompanies }),
    [startDate, endDate, selectedCompanies]
  );

  useEffect(() => {
    const { startDate } = getStartDate(selectedValue.value);
    if (startDate) {
      setStartDate(startDate);
      setEndDate(dayjs());
    }
  }, [selectedValue]);

  const userItems = [
    {
      key: '12',
      label: 'Zamówienia',
      children: (
        <ExpensesMonthly
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    },
    {
      key: '13',
      label: 'Oszczędności',
      children: (
        <SavingsMonthly
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    },
    {
      key: '14',
      label: 'Wydatki',
      children: (
        <ExpensesByCategory
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    },
    {
      key: '15',
      label: 'Dostawcy',
      children: (
        <ProductsRealisationBySupplier
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    },
    {
      key: '16',
      label: 'Czas reakcji dostawców',
      children: (
        <TimeOfResponseOfSingleOrder
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    },
    {
      key: '17',
      label: 'Najczęściej zamawiane produkty',
      children: (
        <TopProducts
          selectedCompanyId={selectedCompanyId}
          filters={filters}
          params={params}
        />
      )
    }
  ];

  const adminItems = [
    {
      key: '1',
      label: 'Globalne statystyki aplikacji',
      children: (
        <>
          <AdminSummary />
          <PricesChangeSummary />
          <ProductsSaleSummary />
          <ReportGenerator />
        </>
      )
    },
    {
      key: '2',
      label: 'Zamówienia',
      children: (
        <ExpensesMonthly
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    },
    {
      key: '3',
      label: 'Oszczędności',
      children: (
        <SavingsMonthly
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      )
    }
  ];

  return (
    <>
      <SeoHelmet title={pages.analytics.title} />
      <PageHeader name={pages.analytics.title}>
        <OrdersSummary
          selectedCompanyId={selectedCompanyId}
          filters={filters}
        />
      </PageHeader>
      <StickyPageHeader
        className={style.justifyLeft}
        buttonsWrapperClasses={style.buttonWrapper}
        containerClasses={style.headerContainer}
      >
        <DatePickerWrapper
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
        <FilterByCompany
          label={'Firma'}
          params={params}
          fullWidth={isAdmin}
          isMulti
        />
        <CompanySelect
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
        />
      </StickyPageHeader>
      <div className={style.container}>
        <Tabs
          defaultActiveKey='1'
          items={isAdmin ? adminItems : userItems}
        />
      </div>
    </>
  );
};

export default Analytics;
