import React, { useState } from 'react';
import dayjs from 'dayjs';

import { AsyncSelect, Button, Card, DateRangePicker, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, StatisticsApi } from 'src/api';

import styles from './ReportGenerator.module.scss';

const ReportGenerator = () => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validator = useValidator();

  const handleGenerateReport = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const params = {
      company_id: selectedCompany.value,
      ...(startDate && endDate && { date_from: dayjs(startDate).toISOString(), date_to: dayjs(endDate).toISOString() })
    };

    try {
      setIsLoading(true);
      const { data, headers } = await StatisticsApi.generateReport(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      window.open(url, '_blank');
    } catch (err) {
      notifyApiError([err.message]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Card>
        <h2 className={styles.title}>Generowanie raportu</h2>
        <form
          onSubmit={handleGenerateReport}
          className={styles.form}
        >
          <AsyncSelect
            value={selectedCompany}
            onChange={setSelectedCompany}
            apiCallback={AdminApi.getCompanies}
            queryParams={{ show_deactivated: 0 }}
            valueKey={'id'}
            labelKey={'name'}
            placeholder={'Firma'}
            label={'Nazwa firmy'}
            isClearable
            validator={validator}
            rule={'required'}
            id={'company_id'}
            name={'company_id'}
          />
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            disabledDate={false}
          />
          <Button
            label={'Generuj'}
            isLoading={isLoading}
          />
        </form>
      </Card>
    </div>
  );
};

export default ReportGenerator;
