import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import { Loader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ReplacementsApi } from 'src/api';

import ProductReplacementsCreateOrAddToGroupFormForm from './components/ProductReplacementsCreateOrAddToGroupFormForm';
import ProductReplacementsTable from './components/ProductReplacementsTable';

import styles from './ProductReplacements.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductReplacements = ({ productId, canLoad, replacementsCount, hasGroup, refreshCallback }) => {
  const [replacements, setReplacements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async (hasGroup) => {
    if (!hasGroup) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const { data } = await ReplacementsApi.getProductReplacements(productId);
      setReplacements(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canLoad) getData(hasGroup);
  }, [canLoad, replacementsCount, hasGroup]);

  const titleElement = (
    <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>
      Zamienniki{' '}
      {isLoading ? (
        <Skeleton
          width={30}
          height={20}
        />
      ) : (
        `(${replacementsCount}) ${hasGroup ? 'Grupa: ' + replacements.name : ''}`
      )}
    </h3>
  );

  if (isLoading)
    return (
      <div className={styles.loadingStateContainer}>
        <Loader />
      </div>
    );

  return (
    <div className={styles.container}>
      {hasGroup ? (
        <ProductReplacementsTable
          {...replacements}
          productId={productId}
          refreshCallback={refreshCallback}
        />
      ) : (
        <ProductReplacementsCreateOrAddToGroupFormForm
          productId={productId}
          refreshCallback={refreshCallback}
        />
      )}
    </div>
  );
};

export default ProductReplacements;
