import React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import numeral from 'numeral';

import { Tag } from 'components';

import errorPlaceholder from 'src/assets/img/product-thumbnail.png';
import {
  AddedPriceTypes,
  availabilities,
  expensesBillingPeriods,
  expensesEvents,
  expensesPaymentMethods,
  invoiceEvents,
  invoiceStatuses,
  invoiceTypes,
  listEvents,
  OCRInvoiceStatuses,
  orderApprovalStatuses,
  orderEvents,
  orderStatuses,
  priceTypes,
  productStatuses,
  releaseTypes,
  servicesEvents,
  supplyTypes,
  tagsEvents,
  userPermissions,
  userPermissionsList,
  valuationStatuses,
  warehouseEvents
} from 'src/constants/enums';
import { cancelledOrderStatusProgress, defaultOrderStatusProgress, orderWithAcceptStatusProgress } from 'src/constants/steps';

import { toDate, toTime } from './dateTime';

export const translatedMonthsArray = ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'];

export const translatedMonths = {
  January: 'Sty',
  February: 'Lut',
  March: 'Mar',
  April: 'Kwi',
  May: 'Maj',
  June: 'Cze',
  July: 'Lip',
  August: 'Sie',
  September: 'Wrz',
  October: 'Paź',
  November: 'Lis',
  December: 'Gru'
};

export const getFormattedAmount = (value, suffix = 'zł') =>
  value === 'NaN'
    ? 'NaN'
    : numeral(value).format('0,0.00').replace(/,/g, ' ').replace(/[.]/, ',') + `${suffix ? ` ${suffix}` : ''}`;

export const getNumberWithThousandsSeparator = (value, suffix) =>
  value === 'NaN' ? 'NaN' : numeral(value).format('0,0').replace(/,/g, ' ') + `${suffix ? ` ${suffix}` : ''}`;

export const translateMonths = (value) => {
  const monthName = value?.split(' ');
  return translatedMonths[monthName[0]] + `${' '}'` + monthName[1].slice(2, 4);
};

const getFormattedDate = (date) => {
  const dateObject = new Date(date);
  const year = dateObject.getFullYear().toString().slice(2, 4);
  const month = translatedMonthsArray[dateObject.getMonth()];
  return `${month} '${year}`;
};

export const translateMonthsWithInfo = (value, { endDate }) => {
  const monthFinal = translateMonths(value);
  const endFormatted = getFormattedDate(endDate);
  return monthFinal + (endFormatted === monthFinal ? '*' : '');
};

export const getProductAvailability = (availability, avData, isReplacement) => {
  if (isReplacement) {
    switch (availability) {
      case availabilities.available:
        return 'Zamiennik dostępny';
      case availabilities.availableIn:
        return `Zamiennik dostępny za ${avData} ${avData === 1 ? 'dzień' : 'dni'}`;
      case availabilities.availableSince:
        return `Zamiennik dostępny od ${moment(avData).format('DD/MM/YYYY')}`;
      case availabilities.notAvailable:
        return 'Brak Zamiennika';
      default:
        return 'Brak danych';
    }
  }

  switch (availability) {
    case availabilities.available:
      return 'Produkt dostępny';
    case availabilities.availableIn:
      return `Dostępny za ${avData} ${avData === 1 ? 'dzień' : 'dni'}`;
    case availabilities.availableSince:
      return `Dostępny od ${moment(avData).format('DD/MM/YYYY')}`;
    case availabilities.notAvailable:
      return 'Brak Produktu';
    default:
      return 'Brak danych';
  }
};

export const getNotificationIdFromName = (errors) => {
  const arr = [];

  if (typeof errors?.length !== 'number') {
    return 'error';
  }

  for (const element of errors) {
    arr.push(...element.split(''));
  }

  let sum = 1;
  arr.forEach((char) => (sum *= char.charCodeAt(0)));

  if (sum === Infinity) {
    sum = Math.random() * 1000000;
  } else {
    while (sum >= 1000000) {
      sum = sum / 2;
    }
  }

  return String(sum.toFixed(0));
};

export const getPagesNumber = (data) => {
  return Math.ceil(data.total / data.per_page);
};

export const getProductStatus = (status) => {
  switch (status) {
    case 'PENDING':
      return 'Oczekuje na potwierdzenie';
    case 'PROCESSING':
      return 'Potwierdzono';
    case 'COMPLETED':
      return 'Otrzymano';
    case 'SENT':
      return 'Wysłano';
    case 'REFUNDED':
      return 'Zwrócono';
    default:
      return '';
  }
};

export const getUserRoleLabel = (role) => {
  switch (role) {
    case 'user':
      return 'Użytkownik';
    case 'admin':
      return 'Administrator';
    case 'supplier':
      return 'Dostawca';
    case 'distributor':
      return 'Dystrybutor';
    default:
      return role;
  }
};

export const getRoleColor = (role) => {
  switch (role) {
    case 'user':
      return 'green';
    case 'admin':
      return 'red';
    case 'supplier':
      return 'yellow';
    case 'distributor':
      return 'blue';
    default:
      return '';
  }
};

export const getRoleColorCode = (role) => {
  switch (role) {
    case 'user':
      return '#3e877e';
    case 'admin':
      return '#ea6c64';
    case 'supplier':
      return '#f5d060';
    case 'distributor':
      return '#6a35ff';
    default:
      return '';
  }
};

export const calculateFinalProductPrice = (priceType, localPrice, qty, localDiscount, tax) => {
  const productSum = localPrice * qty;
  const taxPercentages = (100 + tax) / 100;
  const discountValue = (100 - localDiscount) / 100;

  return priceType.value === priceTypes.brutto
    ? getFormattedAmount(productSum * discountValue)
    : getFormattedAmount(productSum * taxPercentages * discountValue);
};

export const getPriceTypeObject = (priceType) => priceTypes.find((type) => type.value === priceType) || priceTypes[0];

export const handleNumeralWords = (words, value) => {
  if (value === 1) {
    return words[0];
  } else if (value % 100 > 10 && value % 100 < 21) {
    return words[2];
  } else if (value % 10 <= 4 && value % 10 > 1) {
    return words[1];
  } else {
    return words[2];
  }
};

export const getCorrectFormOfResultsLabel = (value, arr = ['wynik', 'wyniki', 'wyników']) =>
  `${value} ${handleNumeralWords(arr, value)}`;

export const scrollToErrors = () => {
  setTimeout(() => {
    const firstErrorMessage = document.querySelector('.srv-validation-message');
    if (firstErrorMessage) {
      firstErrorMessage.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      });
    }
  }, 100);
};

export const bytesToSize = (fileSizeInBytes) => {
  const byteUnits = [' kB', ' MB', ' GB', ' TB'];
  let i = -1;
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const getDecodeBase64File = (base64, type = 'application/pdf') => {
  const binary = window.atob(base64.replace(/\s/g, ''));
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return new Blob([view], { type });
};

export const downloadFile = (file, name) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = name;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const openFileInNewTab = (url) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('target', '_blank');

  document.body.appendChild(link);
  link.click();
};

export const getFormattedPhoneNumber = formatPhoneNumberIntl;

export const getFormattedUnits = (data = []) =>
  data.map((unit) => ({
    label: unit.unit_name,
    value: unit.id,
    is_collection: unit.is_collection
  }));

export const getAllCategories = (categories = []) => {
  const allCategoriesArray = [];

  const getChildCategory = (arrayOfSubCategories, arrayOfAllCategories, depth = 0) => {
    arrayOfSubCategories?.forEach((subCategory) => {
      arrayOfAllCategories.push({ ...subCategory, depth });

      if (subCategory.children?.length > 0) {
        getChildCategory(subCategory.children, arrayOfAllCategories, depth + 1);
      }
    });
  };

  getChildCategory(categories, allCategoriesArray);
  return allCategoriesArray;
};

export const getSelectedUnits = (units, id, legacy) => {
  const selectedUnit = units.find(({ value }) => value === id);
  if (!selectedUnit) {
    return units.find(({ label }) => label === legacy);
  }
  return selectedUnit;
};

export const getGiftsValue = (gifts = []) => {
  let amount = 0;
  gifts.forEach((gift) => (amount += gift.price));
  return amount;
};

const getStatusTag = (tagData, returnData, className) => {
  if (returnData) {
    return tagData;
  } else {
    return (
      <Tag
        value={tagData.title}
        color={tagData.color}
        className={className}
      />
    );
  }
};

export const getProductStatusTag = (status, returnData, className) => {
  const dictionary = {
    [productStatuses.draft]: {
      title: 'Wersja robocza',
      color: 'gray'
    },
    [productStatuses.waiting_for_approval]: {
      title: 'Oczekuje na akceptację',
      color: 'darkGray'
    },
    [orderApprovalStatuses.approved]: {
      title: 'Zaakceptowano',
      color: 'green'
    },
    [orderApprovalStatuses.declined]: {
      title: 'Odrzucono',
      color: 'red'
    },
    [productStatuses.pending]: {
      title: 'Oczekuje na potwierdzenie',
      color: ''
    },
    [productStatuses.processing]: {
      title: 'W realizacji',
      color: 'yellow'
    },
    [productStatuses.sent]: {
      title: 'Wysłano',
      color: 'blue'
    },
    [productStatuses.completed]: {
      title: 'Zrealizowano',
      color: 'green'
    },
    [productStatuses.cancelled]: {
      title: 'Anulowano',
      color: 'red'
    },
    [productStatuses.refunded]: {
      title: 'Zwrócono',
      color: 'blue'
    },
    [productStatuses.complaint]: {
      title: 'W reklamacji',
      color: 'blue'
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData, className);
  } catch (err) {
    return null;
  }
};

export const getUserValuationStatusTag = ({ status, returnData }) => {
  const dictionary = {
    [valuationStatuses.pending]: {
      title: 'W trakcie',
      color: 'yellow'
    },
    [valuationStatuses.sent]: {
      title: 'Gotowa',
      color: 'green'
    },
    [valuationStatuses.cancelled]: {
      title: 'Anulowana',
      color: 'red'
    },
    [valuationStatuses.expired]: {
      title: 'Nieaktualna',
      color: ''
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData);
  } catch (err) {
    return null;
  }
};

export const getOCRInvoiceStatusTag = ({ status, returnData, className }) => {
  const dictionary = {
    [OCRInvoiceStatuses.processing]: {
      title: 'W trakcie',
      color: 'yellow'
    },
    [OCRInvoiceStatuses.error]: {
      title: 'Error',
      color: 'red'
    },
    [OCRInvoiceStatuses.parsed]: {
      title: 'Gotowa',
      color: 'green'
    },
    [OCRInvoiceStatuses.added]: {
      title: 'Dodana',
      color: 'blue'
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData, className);
  } catch (err) {
    return null;
  }
};

export const getValuationStatusTag = (status, returnData) => {
  const dictionary = {
    [valuationStatuses.pending]: {
      title: 'Do wyceny',
      color: 'yellow'
    },
    [valuationStatuses.sent]: {
      title: 'Wysłano',
      color: 'green'
    },
    [valuationStatuses.cancelled]: {
      title: 'Anulowano',
      color: 'red'
    },
    [valuationStatuses.expired]: {
      title: 'Wygasła',
      color: ''
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData);
  } catch (err) {
    return null;
  }
};

export const clientOrderStatus = ({ sup, returnData, forUser, wholeOrderStatus, className }) => {
  const isSent = (sup?.trackingnumber || sup?.traclingurl) && sup.status === orderStatuses.sent;
  return getOrderStatusTag({ status: isSent ? 'with-tracking' : sup.status, returnData, forUser, wholeOrderStatus, className });
};

export const getSupplyTypeTag = ({ type, returnData, className }) => {
  const dictionary = {
    [supplyTypes.orderSupply]: {
      title: 'Z zamówienia',
      color: 'gray'
    },
    [supplyTypes.manualSupply]: {
      title: 'Ręczna',
      color: 'green'
    },
    [supplyTypes.inventorySupply]: {
      title: 'Inwentaryzacja',
      color: 'yellow'
    }
  };

  try {
    return getStatusTag(dictionary[type], returnData, className);
  } catch (err) {
    return type;
  }
};

export const getReleaseTypeTag = ({ type, returnData, className }) => {
  const dictionary = {
    [releaseTypes.manualRelease]: {
      title: 'Ręczne',
      color: 'green'
    },
    [releaseTypes.inventoryRelease]: {
      title: 'Inwentaryzacja',
      color: 'yellow'
    }
  };

  try {
    return getStatusTag(dictionary[type], returnData, className);
  } catch (err) {
    return type;
  }
};

export const getEventTypeTag = ({ type, returnData, className }) => {
  const dictionary = {
    FIX: {
      title: 'Naprawa',
      color: 'red'
    },
    SERVICE: {
      title: 'Serwis',
      color: 'green'
    },
    '': {
      title: 'Brak',
      color: 'gray'
    }
  };

  try {
    return getStatusTag(dictionary[type], returnData, className);
  } catch (err) {
    return type;
  }
};

export const getOrderStatusTag = ({ status, returnData, wholeOrderStatus, className }) => {
  const dictionary = {
    [orderStatuses.draft]: {
      title: 'Wersja robocza',
      color: 'gray'
    },
    [orderStatuses.waiting_for_approval]: {
      title: 'Oczekuje na akceptację',
      color: 'darkGray'
    },
    [orderStatuses.pending]: {
      title: wholeOrderStatus ? 'W realizacji' : 'Oczekuje na potwierdzenie',
      color: wholeOrderStatus ? 'yellow' : ''
    },
    [orderStatuses.processing]: {
      title: 'W realizacji',
      color: 'yellow'
    },
    [orderStatuses.sent]: {
      title: 'Wysłano',
      color: 'blue'
    },
    [orderStatuses.completed]: {
      title: 'Zrealizowano',
      color: 'green'
    },
    [orderStatuses.cancelled]: {
      title: 'Anulowano',
      color: 'red'
    },
    'with-tracking': {
      title: 'Wysłano',
      color: 'blue'
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData, className);
  } catch (err) {
    return null;
  }
};

export const getApprovalStatusTag = ({ status, returnData, className }) => {
  const dictionary = {
    [orderApprovalStatuses.waiting_for_approval]: {
      title: 'Oczekuje na akceptację',
      color: 'darkGray'
    },
    [orderApprovalStatuses.approved]: {
      title: 'Zaakceptowano',
      color: 'green'
    },
    [orderApprovalStatuses.declined]: {
      title: 'Odrzucono',
      color: 'red'
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData, className);
  } catch (err) {
    return null;
  }
};

export const isValidNip = (nip) => {
  if (typeof nip !== 'string') {
    return false;
  }

  nip = nip.replace(/[ \-]/gi, '');

  let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  let controlNumber = parseInt(nip.substring(9, 10));
  let weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const getDatePlusTime = (date, days) => moment(date).add(days, 'days').toISOString();

export const getIndicatorColor = (depth) => {
  const companyIndicatorColors = ['#96ffd8', '#ffe5b0', '#8a8aff', '#ffb2a8', '#96ffd8', '#ffb2a8', '#8a8aff'];
  return companyIndicatorColors[(depth * Math.PI * 1000).toFixed(0) % 7];
};

export const getThumbnailName = (element, isAdmin) => {
  if (!isAdmin && element?.client_data_hidden) {
    return '#' + element.public_id;
  } else {
    return (
      element?.company_name || (
        <>
          <span>(użytownika)</span> {element?.username}
        </>
      )
    );
  }
};

export const getSortValue = (params, keys) => {
  let sortValue;
  let sortOption;

  keys.forEach((key) => {
    if (params.get(key)) {
      sortValue = key;
      sortOption = params.get(key);
    }
  });

  if (!sortValue || !sortOption) {
    return {};
  }

  return {
    sort_by: sortValue,
    sort_order: sortOption
  };
};

export const formatProducerValue = (prod) => ({
  label: prod?.producer_name,
  value: prod?.id
});

export const formatCompanyValue = (company) => ({
  label: company?.name,
  value: company?.id
});

export const formatUserOption = (user) => ({
  label: user?.name,
  value: user?.id,
  ...user
});

export const formatTagOption = (tag) => ({
  label: tag?.name,
  value: tag?.id,
  ...tag
});

export const imageErrorHandler = (event) => {
  event.currentTarget.onerror = null;
  event.currentTarget.src = errorPlaceholder;
};

export const resetWrapperScrollPosition = (selector = '') => {
  const div = document.querySelector(selector);
  if (div) div.scrollTop = 0;
};

export const supplierColors = [
  '#FFD584',
  '#94E5FF',
  '#DAABFF',
  '#9BB1FF',
  '#FFB198',
  '#D6EC4E',
  '#D8B3A8',
  '#66EFEF',
  '#98E97B',
  '#FF9D9D',
  '#FFED4A',
  '#D6D6D6'
];
export const getRandomSupplierColor = (id) => {
  const numberColorDependsOn = id || (Math.random() * 999).toFixed();
  return supplierColors[numberColorDependsOn % supplierColors.length];
};

export const setAllSuppliersCheckedValue = (suppliers = [], value = false) =>
  suppliers.map((supplier) => ({
    ...supplier,
    checked: value
  }));

export const changeSupplierCheckedValue = (suppliers = [], id) =>
  suppliers.map((sup) => (sup?.id !== id ? sup : { ...sup, checked: !sup.checked }));

export const getSupplierBackgroundColor = (sup, fallbackColor = '#eceef1') => ({
  background: (sup?.color || fallbackColor) + 'af'
});

export const getMedian = (numbers) => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
};

export const compareArrays = (first, second) => {
  if (Array.isArray(first) && Array.isArray(second)) {
    if (first.length !== second.length) return false;

    const arrayA = first.sort((a, b) => a - b);
    const arrayB = second.sort((a, b) => a - b);

    let areTheSame = true;

    for (let i = 0; i < arrayA.length; i++) {
      if (arrayA[i] !== arrayB[i]) {
        areTheSame = false;
        break;
      }
    }

    return areTheSame;
  } else if (!Array.isArray(first)) {
    return second[0] === first;
  } else {
    return first[0] === second;
  }
};

export const getPermissionsObject = (userArr) => {
  const obj = {};

  userPermissionsList.forEach((permission) => {
    obj[permission] = userArr.includes(permission) || userArr.includes(permission?.name);
  });

  return obj;
};

export const getAreAllPermissionsSelected = (permissions) => {
  let areAllSelected = true;
  for (const [key, value] of Object.entries(permissions)) {
    if (key === userPermissions.company.admin) continue;
    if (!value) areAllSelected = false;
  }
  return areAllSelected;
};
export const getFormattedPermissionObject = (arr, value) => {
  let obj = {};
  arr.forEach((perm) => (obj[perm] = value));
  return obj;
};

export const suppliersCancelledProductsSum = (sup) =>
  sup.products.filter((prod) =>
    [productStatuses.cancelled, productStatuses.pending, productStatuses.draft, productStatuses.waiting_for_approval].includes(
      prod.status
    )
  ).length;
export const getOrderRealizationSum = (suppliers = []) => {
  const calculateSum = (array) => {
    return array?.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
  };

  const allProductsArray = suppliers.map((sup) => sup.products.length);
  const allProductsSum = calculateSum(allProductsArray);

  const cancelledArray = suppliers.map(suppliersCancelledProductsSum);
  const cancelledSum = calculateSum(cancelledArray);

  const realizationSum = allProductsSum - cancelledSum;
  const percentages = Math.round((realizationSum * 100) / allProductsSum) || 0;

  return { percentages, realizationSum, allProductsSum };
};

export const compareExpenseValues = (values) => {
  if (!values) return;

  let content = '';
  const {
    old_billing_period,
    billing_period,
    old_category_name,
    category_name,
    old_comment,
    comment,
    old_for_negotiation,
    for_negotiation,
    old_payment_method,
    payment_method
  } = values;

  if (old_category_name !== category_name) {
    content += `${old_category_name} -> ${category_name}</br>`;
  }
  if (old_billing_period !== billing_period) {
    content += `${getExpenseBillingPeriodName(old_billing_period)} -> ${getExpenseBillingPeriodName(billing_period)}</br>`;
  }
  if (old_payment_method !== payment_method) {
    content += `${getExpensePaymentName(old_payment_method)} -> ${getExpensePaymentName(payment_method)}</br>`;
  }
  if (Number(old_for_negotiation) !== Number(for_negotiation)) {
    content += for_negotiation === '1' ? 'Dodano tag: Do negocjacji</br>' : 'Usunięto tag: Do negocjacji<br/>';
  }
  if (old_comment !== comment) {
    content += `Zmieniono komentarz na: ${comment || '-'}</br>`;
  }

  return content;
};

export const compareExpensePaymentValues = (values) => {
  if (!values) return;

  let content = '';
  const { old_comment, comment, old_name, name, old_payment_date, payment_date, old_price, price, old_nip, nip } = values;

  if (old_name !== name) {
    content += `Nazwa: ${old_name} -> ${name}</br>`;
  }
  if (old_nip !== nip) {
    content += `Zmieniono NIP na: ${nip}</br>`;
  }
  if (Number(old_price) !== Number(price)) {
    content += `Kwota: ${getFormattedAmount(Number(old_price))} -> ${getFormattedAmount(Number(price))}</br>`;
  }
  if (old_payment_date !== payment_date) {
    content += `Data: ${old_payment_date} -> ${payment_date}</br>`;
  }
  if (old_comment !== comment) {
    content += `Zmieniono komentarz na: ${comment || '-'}</br>`;
  }

  return content;
};

export const getActionsHistoryContent = (item) => {
  const { event, event_type, supplier_name, user_name, value } = item;
  let content = value;
  if (typeof value === 'string') {
    try {
      content = JSON.parse(value);
    } catch (err) {
      console.error(err);
      content = null;
    }
  }

  switch (event || event_type) {
    // SERVICES EVENTS
    case servicesEvents.NEW_SERVICE:
      return {
        title: 'Dodano sprzęt',
        color: 'green',
        content: `Użytkownik <strong>${value?.user_name}</strong> dodał nowy sprzęt <strong>${value?.service_name}</strong>`
      };
    case servicesEvents.EDITED_SERVICE:
      return {
        title: 'Edytowano sprzęt',
        content: `Użytkownik <strong>${value?.user_name}</strong> edytował sprzęt <strong>${value?.service_name}</strong>`
      };
    // TAGS EVENTS
    case tagsEvents.NEW_TAG:
      return {
        title: 'Dodano tag',
        color: 'green',
        content: `Użytkownik <strong>${value?.user_name}</strong> dodał nowy tag <strong>${value?.tag_name}</strong>`
      };
    case tagsEvents.EDITED_TAG:
      return {
        title: 'Edytowano tag',
        content: `Użytkownik <strong>${value?.user_name}</strong> edytował tag <strong>${value?.tag_name}</strong>`
      };
    case tagsEvents.DELETED_TAG:
      return {
        title: 'Usunięto tag',
        color: 'red',
        content: `Użytkownik <strong>${value?.user_name}</strong> usunął tag <strong>${value?.tag_name}</strong>`
      };
    // WAREHOUSE EVENTS
    case warehouseEvents.MANUAL_SUPPLY:
      return {
        title: 'Dostawa',
        content: `${item?.quantity} szt. produktu: ${item?.product_name}`,
        color: 'green',
        triggerer: 'user'
      };
    case warehouseEvents.ORDER_SUPPLY:
      return {
        title: 'Dostawa z zamówienia',
        content: `${item?.quantity} szt. produktu: ${item?.product_name}`,
        color: 'green',
        triggerer: 'user'
      };
    case warehouseEvents.INVENTORY_SUPPLY:
      return {
        title: 'Dodano produkt - inwentaryzacja',
        content: `${item?.quantity} szt. produktu: ${item?.product_name}`,
        color: 'green',
        triggerer: 'user'
      };
    case warehouseEvents.MANUAL_RELEASE:
      return {
        title: 'Wydanie z magazynu',
        content: `${item?.quantity} szt. produktu: ${item?.product_name}`,
        triggerer: 'user'
      };
    case warehouseEvents.INVENTORY_RELEASE:
      return {
        title: 'Wydano produkty - inwentaryzacja',
        content: `${item?.quantity} szt. produktu: ${item?.product_name}`,
        triggerer: 'user'
      };
    // EXPENSES EVENTS
    case expensesEvents.NEW_EXPENSE:
      return {
        title: `Użytkownik ${content?.user_name} dodał wydatek ${content?.category_name}`
      };
    case expensesEvents.EDITED_EXPENSE:
      return {
        title: `Użytkownik ${content?.user_name} zmienił wydatek ${content?.old_category_name}`,
        content: `${compareExpenseValues(content)}`
      };
    case expensesEvents.DELETED_EXPENSE:
      return {
        title: `Użytkownik ${content?.user_name} usunął wydatek ${content?.category_name}`,
        color: 'red'
      };
    case expensesEvents.NEW_EXPENSE_FILE:
      return {
        title: `Użytkownik ${content?.user_name} dodał plik do wydatku ${content?.category_name}`,
        content: `Nazwa pliku: ${content?.file_name}`
      };
    case expensesEvents.DELETED_EXPENSE_FILE:
      return {
        title: `Użytkownik ${content?.user_name} usunął plik z wydatku ${content?.category_name}`,
        content: `Nazwa pliku: ${content?.file_name}`
      };
    case expensesEvents.NEW_EXPENSE_PAYMENT:
      return {
        title: `Użytkownik ${content?.user_name} dodał płatność do wydatku ${content?.category_name}`,
        content: `
          Nazwa: ${content?.name}<br/>
          Kwota: ${getFormattedAmount(Number(content?.price))}<br/>
          Data: ${content?.payment_date}
          ${content?.comment ? '<br/>Komentarz: ' + content?.comment : ''}
        `
      };
    case expensesEvents.EDITED_EXPENSE_PAYMENT:
      return {
        title: `Użytkownik ${content?.user_name} zmienił płatność ${content?.old_name} w wydatku ${content?.category_name}`,
        content: `${compareExpensePaymentValues(content)}`
      };
    case expensesEvents.DELETED_EXPENSE_PAYMENT:
      return {
        title: `Użytkownik ${content?.user_name} usunął płatność ${content?.name} w wydatku ${content?.category_name}`,
        color: 'red'
      };
    case expensesEvents.NEW_EXPENSE_PAYMENT_FILE:
      return {
        title: `Użytkownik ${content?.user_name} dodał plik do płatności ${content?.name} w wydatku ${content?.category_name}`,
        content: `Nazwa pliku: ${content?.file_name}`
      };
    case expensesEvents.DELETED_EXPENSE_PAYMENT_FILE:
      return {
        title: `Użytkownik ${content?.user_name} usunął plik z płatności ${content?.name} w wydatku ${content?.category_name}`,
        content: `Nazwa pliku: ${content?.file_name}`
      };
    // LIST EVENTS
    case listEvents.USER_CREATED_LIST:
      return {
        title: `Lista została utworzona przez użytkownika ${content?.user_name || ''}`,
        triggerer: 'user'
      };
    case listEvents.USER_EDITED_LIST_COMMENT:
      return {
        title: `Użytkownik ${content?.user_name || ''} zmienił komentarz do listy`,
        content: `Nowa treść: ${content?.list_comment || ''}`,
        triggerer: 'user'
      };
    case listEvents.USER_ADDED_PRODUCT_TO_LIST:
      return {
        title: `Użytkownik ${content?.user_name || ''} dodał produkt do listy`,
        content: content?.product_name,
        triggerer: 'user'
      };
    case listEvents.USER_DELETED_PRODUCT_FROM_LIST:
      return {
        title: `Użytkownik ${content?.user_name || ''} usunął produkt z listy`,
        content: content?.product_name,
        color: 'red',
        triggerer: 'user'
      };
    case listEvents.USER_CHANGED_LIST_PRODUCT_QUANTITY:
      return {
        title: `Użytkownik ${content?.user_name || ''} zmienił ilość produktu`,
        content: `Produkt: ${content?.product_name || ''}<br />Nowa ilość: ${content?.quantity || ''}`,
        triggerer: 'user'
      };
    case listEvents.USER_SENT_LIST_TO_VALUATION:
      return {
        title: (
          <>
            {`Lista zostałą wysłana do wyceny przez użytkownika ${content?.user_name || ''}`} [
            <Link to={`/valuations/${content?.pricing_uuid}`}>#{content?.pricing_public_id}</Link>]
          </>
        ),
        list: {
          title: 'Dostawcy, do których została wysłana wycena:',
          content: content?.suppliers_list || []
        },
        triggerer: 'user',
        color: 'yellow'
      };
    case listEvents.USER_PLACED_ORDER_FROM_LIST:
      return {
        title: (
          <>
            {`Użytkownik ${content?.user_name || ''} złożył zamówienie na podstawie listy`} [
            <Link to={`/orders/${content?.order_uid}`}>#{content?.order_public_id}</Link>]
          </>
        ),
        triggerer: 'user',
        color: 'yellow'
      };
    case listEvents.USER_EDITED_LIST_PRODUCT_COMMENT:
      return {
        title: `Użytkownik ${content?.user_name || ''} zmienił komentarz do produktu`,
        content: `Produkt: ${content?.product_name} <br/>Komentarz: ${content?.comment}`,
        triggerer: 'user'
      };
    case listEvents.USER_MERGED_LIST:
      return {
        title: `Użytkownik ${content?.user_name || ''} utworzył nową listę na podstawie list`,
        list: {
          title: 'Połączone listy',
          content: content?.suppliers_list || []
        },
        triggerer: 'user'
      };
    // INVOICE EVENTS
    case invoiceEvents.USER_ADDED_INVOICE:
      return {
        title: `Użytkownik ${content?.user_name || ''} dodał fakturę`,
        content: `Numer faktury: ${content?.number} <br/> Dostawca: ${supplier_name}`,
        triggerer: 'user'
      };
    case invoiceEvents.USER_CHANGED_INVOICE_STATUS:
      return {
        title: `Użytkownik ${content?.user_name || ''} zmienił status faktury`,
        content: `${getInvoiceStatus({ status: content?.old_status, returnData: true }).title} -> ${
          getInvoiceStatus({ status: content?.status, returnData: true }).title
        } <br/> Numer faktury: ${content?.number}`,
        triggerer: 'user'
      };
    case invoiceEvents.USER_EDITED_INVOICE:
      return {
        title: `Użytkownik ${content?.user_name || ''} edytował fakturę`,
        content: `Numer faktury: ${content?.number} <br/> Dostawca: ${supplier_name}`,
        triggerer: 'user'
      };
    case invoiceEvents.USER_DELETED_INVOICE:
      return {
        title: `Użytkownik ${content?.user_name || ''} usunął fakturę`,
        content: `Numer faktury: ${content?.number} <br/> Dostawca: ${supplier_name}`,
        triggerer: 'user'
      };

    case invoiceEvents.USER_ADDED_INVOICE_OTHER:
      return {
        title: `Użytkownik ${content?.user_name || ''} dodał fakturę spoza aplikacji`,
        content: `Numer faktury: ${content?.number} <br/> Dostawca: ${supplier_name}`,
        triggerer: 'user'
      };
    case invoiceEvents.USER_CHANGED_INVOICE_OTHER_STATUS:
      return {
        title: `Użytkownik ${content?.user_name || ''} zmienił status faktury spoza aplikacji`,
        content: `${getInvoiceStatus({ status: content?.old_status, returnData: true }).title} -> ${
          getInvoiceStatus({ status: content?.status, returnData: true }).title
        } <br/> Numer faktury: ${content?.number}`,
        triggerer: 'user'
      };
    case invoiceEvents.USER_EDITED_INVOICE_OTHER:
      return {
        title: `Użytkownik ${content?.user_name || ''} edytował fakturę spoza aplikacji`,
        content: `Numer faktury: ${content?.number} <br/> Dostawca: ${supplier_name}`,
        triggerer: 'user'
      };
    case invoiceEvents.USER_DELETED_INVOICE_OTHER:
      return {
        title: `Użytkownik ${content?.user_name || ''} usunął fakturę spoza aplikacji`,
        content: `Numer faktury: ${content?.number} <br/> Dostawca: ${supplier_name}`,
        triggerer: 'user'
      };
    // ORDERS EVENTS
    case orderEvents.STATUS_CHANGED_TO_DRAFT:
      return {
        title: 'Wersja robocza zamówienia została utworzona',
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_PARTIAL_RECEIVED:
      return {
        title: `Odebrano ${content?.received_quantity} / ${content?.total_quantity} ${content?.unit_name} ${content?.product_name}`,
        content: `Do odebrania zostało ${(content?.total_quantity ?? 0) - (content?.new_received_quantity ?? 0)} ${
          content?.unit_name
        }`,
        triggerer: 'user'
      };
    case orderEvents.SUPPLIER_OPENED_NEW_ORDER_EMAIL:
      return {
        title: 'Dostawca otworzył wiadomość z zamówieniem',
        triggerer: 'supplier'
      };
    case orderEvents.SUPPLIER_REOPENED_NEW_ORDER_EMAIL:
      return {
        title: 'Dostawca otworzył ponownie wiadomość z zamówieniem',
        triggerer: 'supplier'
      };
    case orderEvents.STATUS_CHANGED_TO_WAITING_FOR_APPROVAL:
      return {
        title: 'Zamówienie zostało wysłane do akceptacji',
        triggerer: 'user'
      };
    case orderEvents.ORDER_APPROVED:
      return {
        title: 'Zamówienie zostało zaakceptowane',
        color: 'green',
        approver: `${content?.first_name} ${content?.last_name} (${content?.email})`
      };
    case orderEvents.ORDER_DECLINED:
      return {
        title: 'Zamówienie zostało odrzucone',
        approver: `${content?.first_name} ${content?.last_name} (${content?.email})`,
        color: 'red'
      };
    case orderEvents.STATUS_CHANGED_TO_PENDING:
      return {
        title: 'Zamówienie zostało złożone'
      };
    case orderEvents.STATUS_CHANGED_TO_PROCESSING:
      return {
        title: 'Zamówienie zostało zaakceptowane przez dostawcę',
        color: 'yellow',
        triggerer: 'supplier'
      };
    case orderEvents.STATUS_LINK_CHANGED_TO_CANCELLED:
    case orderEvents.STATUS_CHANGED_TO_CANCELLED:
      return {
        title: 'Zamówienie zostało anulowane',
        color: 'red',
        triggerer: 'user'
      };
    case orderEvents.ORDER_CANCELLED_BY_SUPPLIER:
      return {
        title: 'Zamówienie zostało anulowane przez dostawcę',
        triggerer: 'supplier',
        color: 'red'
      };
    case orderEvents.STATUS_CHANGED_TO_COMPLETED:
      return {
        title: 'Zamówienie zostało zrealizowane',
        color: 'green',
        triggerer: 'user'
      };
    case orderEvents.STATUS_CHANGED_TO_SENT:
      return {
        title: 'Zamówienie zostało wysłane',
        color: 'blue',
        triggerer: 'supplier'
      };
    case orderEvents.PRODUCT_PRICE_CHANGED_BY_ADMIN:
    case orderEvents.PRODUCT_PRICE_CHANGED: {
      const delta = content?.new_price - content?.old_price;
      const gfa = getFormattedAmount;
      return {
        title: `Cena produktu ${delta > 0 ? 'wzrosła' : 'spadła'} o ${gfa(Math.abs(delta))}`,
        content: `zmiana z ${gfa(content?.old_price)} na ${gfa(content?.new_price)}`,
        product: content?.order_product_id,
        triggerer: event === orderEvents.PRODUCT_PRICE_CHANGED ? 'supplier' : 'user'
      };
    }
    case orderEvents.PRODUCT_QUANTITY_CHANGE:
      return {
        title: `Liczba produktu została zmieniona z ${content?.old_quantity} na ${content?.new_quantity}`,
        product: content?.order_product_id,
        triggerer: 'supplier'
      };

    case orderEvents.USER_SEND_MESSAGE:
      return {
        title: `Użytkownik ${user_name || ''} wysłał wiadomość`,
        content: content?.message ? '"' + content?.message + '"' : '',
        triggerer: 'user'
      };
    case orderEvents.SUPPLIER_SEND_MESSAGE:
      return {
        title: `Dostawca ${supplier_name || ''} wysłał wiadomość`,
        content: content?.message ? '"' + content?.message + '"' : '',
        triggerer: 'supplier'
      };

    case orderEvents.USER_ADDED_ATTACHMENT:
      return {
        title: `Użytkownik ${user_name || ''} dodał załącznik`,
        content: content?.file_name,
        triggerer: 'user'
      };

    case orderEvents.USER_DELETED_ATTACHMENT:
      return {
        title: `Użytkownik ${user_name || ''} usunął załącznik`,
        content: content?.file_name,
        triggerer: 'user'
      };

    case orderEvents.SUPPLIER_ADDED_ATTACHMENT:
      return {
        title: `Dostawca ${supplier_name || ''} dodał załącznik`,
        content: content?.file_name,
        triggerer: 'supplier'
      };

    case orderEvents.SUPPLIER_DELETED_ATTACHMENT:
      return {
        title: `Dostawca ${supplier_name || ''} usunął załącznik`,
        content: content?.file_name,
        triggerer: 'supplier'
      };

    case orderEvents.SUPPLIER_ADDED_INVOICE:
      return {
        title: 'Dostawca załączył fakturę do zamówienia',
        triggerer: 'supplier'
      };

    case orderEvents.PRODUCT_REFUNDED_BY_USER:
      return {
        title: `${handleNumeralWords(
          ['Produkt został zwrócony', 'Produkty zostały zwrócone', 'Produkty zostały zwrócone'],
          content?.amount
        )} (${content?.amount}):`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_COMPLETED_BY_USER:
      return {
        title: `${handleNumeralWords(
          ['Produkt został odebrany', 'Produkty zostały odebrane', 'Produkty zostały odebrane'],
          content?.amount
        )}${content?.amount > 1 ? ` (${content?.amount})` : ''}:`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_CANCELLED:
    case orderEvents.PRODUCT_CANCELLED_BY_USER:
      return {
        title: `${handleNumeralWords(
          ['Produkt został anulowany', 'Produkty zostały anulowane', 'Produkty zostały anulowane'],
          content?.amount
        )} (${content?.amount}):`,
        products: content?.product_ids,
        color: 'red',
        triggerer: event === orderEvents.PRODUCT_CANCELLED_BY_USER ? 'user' : 'supplier'
      };
    case orderEvents.PRODUCT_COMPLAINT_BY_USER:
      return {
        title: `${handleNumeralWords(
          ['Produkt został poddany reklamacji', 'Produkty zostały poddane reklamacji', 'Produkty zostały poddane reklamacji'],
          content?.amount
        )} (${content?.amount}):`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_COMPLAINT_BY_USER_REVERSE:
      return {
        title: `Przywrócono status "${getProductStatusTag(productStatuses.complaint, true).title}" dla ${
          content?.amount
        } ${handleNumeralWords(['produktu', 'produktów', 'produktów'], content?.amount)}:`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_PENDING_BY_USER_REVERSE:
      return {
        title: `Przywrócono status "${getProductStatusTag(productStatuses.pending, true).title}" dla ${
          content?.amount
        } ${handleNumeralWords(['produktu', 'produktów', 'produktów'], content?.amount)}:`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_PROCESSING_BY_USER_REVERSE:
      return {
        title: `Przywrócono status "${getProductStatusTag(productStatuses.processing, true).title}" dla ${
          content?.amount
        } ${handleNumeralWords(['produktu', 'produktów', 'produktów'], content?.amount)}:`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    case orderEvents.PRODUCT_SENT_BY_USER_REVERSE:
      return {
        title: `Przywrócono status "${getProductStatusTag(productStatuses.sent, true).title}" dla ${
          content?.amount
        } ${handleNumeralWords(['produktu', 'produktów', 'produktów'], content?.amount)}:`,
        products: content?.product_ids,
        triggerer: 'user'
      };
    default:
      return {
        title: ''
      };
  }
};

export const getApprovalUpdatedAt = ({ status, updated_at }) => {
  if (!status || !updated_at) {
    return '';
  } else {
    const prefix = (status === orderApprovalStatuses.declined ? 'Odrzucono' : 'Zaakceptowano') + ': ';
    return prefix + toDate(updated_at) + ', ' + toTime(updated_at);
  }
};

export const getInvoiceStatus = ({ status, returnData, className }) => {
  const dictionary = {
    [invoiceStatuses.DRAFT]: {
      title: 'Do potwierdzenia',
      color: 'blue'
    },
    [invoiceStatuses.TO_PAY]: {
      title: 'Nieopłacona',
      color: 'yellow'
    },
    [invoiceStatuses.COMPLETED]: {
      title: 'Opłacona',
      color: 'green'
    }
  };

  try {
    return getStatusTag(dictionary[status], returnData, className);
  } catch (err) {
    return null;
  }
};

export const getInvoiceType = ({ type, returnData, className }) => {
  const dictionary = {
    [invoiceTypes.invoice]: {
      title: 'Faktura'
    },
    [invoiceTypes.wz]: {
      title: 'WZ'
    },
    [invoiceTypes.other]: {
      title: 'Inny'
    },
    [invoiceTypes.correction]: {
      title: 'Korekta'
    }
  };

  try {
    return getStatusTag(dictionary[type], returnData, className);
  } catch (err) {
    return null;
  }
};

export const stringToBase64 = (str) =>
  window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode('0x' + p1);
    })
  );

export const scrollToElementAndReturnPromise = (element) =>
  new Promise((resolve) => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            observer.disconnect();
            resolve();
          }
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(element);
    element.setAttribute('tabindex', '0');
    let elementRect = element.getBoundingClientRect();
    let absoluteElementTop = elementRect.top + window.scrollY;
    let middlePosition = absoluteElementTop - window.innerHeight / 3;
    window.scrollTo({ top: middlePosition, behavior: 'smooth' });
  });

export const getStartDate = (value) => {
  const currentMonth = dayjs().month();
  let startDate, endDate;

  switch (value) {
    case 'LAST_30_DAYS':
      startDate = dayjs().subtract(30, 'days');
      endDate = dayjs();
      break;
    case 'LAST_3_MONTHS':
      startDate = dayjs().month(currentMonth - 2);
      endDate = dayjs();
      break;
    case 'LAST_6_MONTHS':
      startDate = dayjs().month(currentMonth - 5);
      endDate = dayjs();
      break;
    case 'LAST_YEAR':
      startDate = dayjs().subtract(1, 'year');
      endDate = dayjs();
      break;
    case 'CURRENT_MONTH':
      startDate = dayjs().startOf('month');
      endDate = dayjs().endOf('month');
      break;
    case 'PREVIOUS_MONTH':
      startDate = dayjs().subtract(1, 'month').startOf('month');
      endDate = dayjs().subtract(1, 'month').endOf('month');
      break;
    case 'CURRENT_QUARTER':
      startDate = dayjs().startOf('quarter');
      endDate = dayjs().endOf('quarter');
      break;
    case 'PREVIOUS_QUARTER':
      startDate = dayjs().startOf('quarter').subtract(1, 'quarter');
      endDate = dayjs().startOf('quarter').subtract(1, 'quarter').endOf('quarter');
      break;
    case 'CURRENT_YEAR':
      startDate = dayjs().startOf('year');
      endDate = dayjs().endOf('year');
      break;
    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
};

export const generatePastelColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const baseRed = 100 + Math.round(Math.random() * 155);
    const baseGreen = 100 + Math.round(Math.random() * 155);
    const baseBlue = 100 + Math.round(Math.random() * 155);

    colors.push(
      `#${baseRed.toString(16).padStart(2, '0')}${baseGreen.toString(16).padStart(2, '0')}${baseBlue
        .toString(16)
        .padStart(2, '0')}`
    );
  }
  return colors;
};

export const getPriceTypeContent = (type) => {
  const prefix = 'Oferta została dodana przez ';

  switch (type) {
    case AddedPriceTypes.adminValuation:
      return {
        label: prefix + 'admina w wycene.'
      };
    case AddedPriceTypes.supplierValuation:
      return {
        label: prefix + 'dostawcę w wycenie.'
      };
    case AddedPriceTypes.supplierOrder:
    case AddedPriceTypes.userAcceptNewPrice:
      return {
        label: prefix + 'dostawcę w zamówieniu.'
      };
    case AddedPriceTypes.adminAcceptNewPrice:
      return {
        label: prefix + 'admina (ręcznie).'
      };
    case AddedPriceTypes.adminImportCatalogPrice:
      return {
        label: prefix + 'admina (import cen).'
      };
    case AddedPriceTypes.adminClientLoginImportCatalogPrice:
      return {
        label: prefix + 'admina w widoku klienta (import cen).'
      };
    case AddedPriceTypes.userImportCatalogPrice:
      return {
        label: prefix + 'użytkownika (import cen).'
      };
    case AddedPriceTypes.userAddPriceOfc:
      return {
        label: prefix + 'użytkownika (panel Ofert).'
      };
    case AddedPriceTypes.adminAddPriceOfc:
      return {
        label: prefix + 'admina (panel Ofert).'
      };
    case AddedPriceTypes.adminClientLoginAddPriceOfc:
      return {
        label: prefix + 'admina w widoku klienta (panel Ofert).'
      };
    case AddedPriceTypes.adminClientLoginValuation:
      return {
        label: prefix + 'admina w widoku klienta (Wyceny).'
      };
    default:
      return { label: type };
  }
};

export const getParamsArray = (arr) => (Array.isArray(arr) ? arr : [arr]);

export const getProducerColumns = (data) =>
  data.suppliers.map((supplier) => {
    const products = data.products.map((product) => {
      const pricesArr = product.prices.find((price) => price.supplier_id === supplier.id || price.type === supplier.id);
      return { product, valuation: pricesArr };
    });
    return { supplier, items: products };
  });

export const getCategoriesAfterSelectionChange = (data, products = []) =>
  products.map((product) => {
    const editedProductData = {
      ...product,
      prices: product.prices.map((price) => ({ ...price, isSelected: price.supplier_id === data.supplierId }))
    };
    return product.id !== data.productId ? product : editedProductData;
  });

export const getProducerColumnsFromCategories = (data) =>
  data.suppliers.map((supplier) => {
    const categories = data.categories.map((cat) => {
      const products = cat.products.map((product) => {
        const pricesArr = product.prices.find((price) => price.supplier_id === supplier.id || price.type === supplier.id);
        return { product, valuation: pricesArr };
      });
      return { category: cat, products: products };
    });
    return { supplier, items: categories };
  });

export const getCategoriesAfterSelectionChangeFromCategories = (data, categories = []) => {
  const { products_pricings_id, distributor_id } = data;

  return categories.map((cat) => ({
    ...cat,
    products: cat.products.map((product) => {
      if (product.pricing_prod_id === products_pricings_id) {
        return {
          ...product,
          prices: product.prices.map((price) => {
            if (price.supplier_id === distributor_id) {
              return { ...price, selected: true };
            }
            return { ...price, selected: false };
          })
        };
      }
      return product;
    })
  }));
};

const getStateForApproval = (current = 0, steps = orderWithAcceptStatusProgress) => ({
  steps,
  current
});

const getStateForDefault = (current = 0, steps = defaultOrderStatusProgress) => ({
  steps,
  current
});

export const getOrderCurrentStep = (order) => {
  const { draft, waiting_for_approval, completed, sent, processing, cancelled, pending } = orderStatuses;

  const status = order?.status;
  const cartsStatuses = order?.suppliers.map((sup) => sup.status) || [];
  const orderWithApproval = order?.order_approval || [draft, waiting_for_approval].includes(status);
  const processingCarts = cartsStatuses.filter((status) => [processing, sent, completed].includes(status));

  const getStepProgress = (step) =>
    step.title === 'W realizacji' ? { ...step, subTitle: `${processingCarts.length} / ${cartsStatuses?.length}` } : step;

  if (status === cancelled) {
    return {
      steps: cancelledOrderStatusProgress,
      current: 1
    };
  }

  if (orderWithApproval) {
    if (status === draft) {
      return getStateForApproval(0);
    } else if (status === waiting_for_approval) {
      return getStateForApproval(1);
    } else if (status === completed) {
      return getStateForApproval(4);
    } else if (cartsStatuses.includes(processing) || cartsStatuses.includes(completed)) {
      return {
        ...getStateForApproval(2),
        steps: getStateForApproval(2).steps.map(getStepProgress)
      };
    } else if (status === pending) {
      return getStateForApproval(2);
    } else {
      return {};
    }
  } else {
    if (status === completed) {
      return getStateForDefault(3);
    } else if (cartsStatuses.includes(processing) || cartsStatuses.includes(completed)) {
      return {
        ...getStateForDefault(2),
        steps: getStateForDefault(2).steps.map(getStepProgress)
      };
    } else if (status === pending) {
      return getStateForDefault(1);
    } else {
      return {};
    }
  }
};

export const getCartCurrentStep = (cart, orderApproval) => {
  const { draft, waiting_for_approval, completed, sent, processing, cancelled, pending } = orderStatuses;

  const status = cart?.status;
  const orderWithApproval = orderApproval || [draft, waiting_for_approval].includes(status);

  if (status === cancelled) {
    return {
      steps: [{}, { status: 'error' }, {}, {}],
      current: 1
    };
  }

  const one = [{}, {}, {}, {}];
  const oneFinished = [{}, {}, {}, { status: 'finish' }];

  const two = [{}, {}, {}, {}, {}];
  const twoFinished = [{}, {}, {}, {}, { status: 'finish' }];

  if (orderWithApproval) {
    if (status === draft) {
      return getStateForApproval(0, two);
    } else if (status === waiting_for_approval) {
      return getStateForApproval(1, two);
    } else if (status === completed) {
      return getStateForApproval(4, twoFinished);
    } else if ([processing, sent].includes(status)) {
      return getStateForApproval(3, two);
    } else if (status === pending) {
      return getStateForApproval(2, two);
    } else {
      return {};
    }
  } else {
    if (status === completed) {
      return getStateForDefault(3, oneFinished);
    } else if ([processing, sent].includes(status)) {
      return getStateForDefault(2, one);
    } else if (status === pending) {
      return getStateForDefault(1, one);
    } else {
      return {};
    }
  }
};

export const getExpenseBillingPeriodName = (key) => {
  return expensesBillingPeriods[key];
};

export const getExpensePaymentName = (key) => {
  return expensesPaymentMethods[key];
};

// PDF generator ----------

const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
};

const getImageDataFromCanvas = (img) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0, img.width, img.height);
  return canvas.toDataURL('image/jpeg');
};

const getImagePosition = (img, doc) => {
  // Calculate dimensions to maintain aspect ratio
  const imgAspectRatio = img.width / img.height;
  let imgHeight = doc.internal.pageSize.getHeight(); // Fit image to page height
  let imgWidth = imgHeight * imgAspectRatio;

  // Adjust width if it exceeds page width
  if (imgWidth > doc.internal.pageSize.getWidth()) {
    imgWidth = doc.internal.pageSize.getWidth();
    imgHeight = imgWidth / imgAspectRatio;
  }

  // Calculate position to center the image
  const x = (doc.internal.pageSize.getWidth() - imgWidth) / 2;
  const y = (doc.internal.pageSize.getHeight() - imgHeight) / 2;

  return { x, y, imgWidth, imgHeight };
};

export const getPdfUrlFromImage = async (imageUrl) => {
  if (!imageUrl) throw new Error('Image url not provided');

  try {
    const pdfGenerator = new jsPDF();
    const img = await loadImage(imageUrl);
    const imgData = getImageDataFromCanvas(img);
    const { x, y, imgWidth, imgHeight } = getImagePosition(img, pdfGenerator);
    pdfGenerator.addImage(imgData, 'JPEG', x, y, imgWidth, imgHeight);
    return pdfGenerator.output('bloburi');
  } catch (error) {
    console.error('Error loading or adding image to PDF', error);
  }
};

export const calculateDifference = (previousPrice, currentPrice) => {
  let prevPrice = previousPrice === null ? 0 : previousPrice;
  let currPrice = currentPrice === null ? 0 : currentPrice;

  const numericDifference = currPrice - prevPrice;
  const sign = numericDifference > 0 ? '+' : numericDifference < 0 ? '-' : '';

  let percentageDifference = 0;
  if (prevPrice !== 0) {
    percentageDifference = (Math.abs(numericDifference) / prevPrice) * 100;
  }

  return {
    percentageDifference: sign + percentageDifference.toFixed(2) + '%',
    numericDifference: sign + getFormattedAmount(Math.abs(numericDifference))
  };
};

export const getTimeDiff = (date) => {
  const parsed = new Date(date);
  return Math.floor((Date.now() - parsed.getTime()) / 1000);
};
