import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { Accordion, AsyncSelect, Chart, DateRangePicker, Select, useIsAdmin } from 'components';
import style from 'components/layout/MoveElementForm/MoveElementForm.module.scss';

import { AdminApi, StatisticsApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import shared from 'styles/Shared.module.scss';

const options = [
  { label: 'Ceny', value: 'data_price' },
  { label: 'Ilość', value: 'data_qty' }
];

const ProductOrdersChart = ({ id, canLoad }) => {
  const isAdmin = useIsAdmin();
  const [endDate, setEndDate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(options[0]);

  const getData = async () => {
    const queryData = {
      id,
      company_id: selectedCompanyId?.value,
      ...(startDate && endDate && { date_from: dayjs(startDate).toISOString(), date_to: dayjs(endDate).toISOString() })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getProductOrders(queryData);
      setChartData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (canLoad) getData();
  }, [startDate, endDate, selectedCompanyId, canLoad]);

  const titleElement = (
    <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>
      Historia cen{' '}
      {isLoading ? (
        <Skeleton
          width={30}
          height={20}
        />
      ) : (
        `(${chartData?.categories?.length || 0})`
      )}
    </h3>
  );

  const categoriesFormatter = (date) => date.replaceAll('/', '.');

  const filteredData = useMemo(() => {
    const dataSource = chartData ? chartData[selectedDataType.value] : [];
    const data = [];
    const categories = [];
    chartData?.categories?.forEach((cat, index) => {
      if (categories.includes(cat)) {
        if (+data[index] < +dataSource[index]) {
          data.push(dataSource[index]);
        }
      } else {
        categories.push(cat);
        data.push(dataSource[index]);
      }
    });
    return { data, categories };
  }, [chartData, selectedDataType, selectedCompanyId]);

  const prices = useMemo(
    () => ({
      avg: filteredData.data.length > 0 ? filteredData.data.reduce((a, c) => a + c, 0) / filteredData.data.length : 0,
      min: filteredData.data.length > 0 ? [...filteredData.data].sort((a, b) => a - b)[0] : 0,
      max: filteredData.data.length > 0 ? [...filteredData.data].sort((a, b) => b - a)[0] : 0
    }),
    [filteredData]
  );

  return (
    <Accordion
      section
      title={titleElement}
      className={classNames(shared.tableCategory)}
      extraDeps={[chartData]}
      defaultWrapped
    >
      <div style={{ padding: '0 20px 0 10px' }}>
        <Chart
          data={filteredData.data}
          categories={filteredData?.categories}
          title={`Ceny produktu w wybranym okresie: średnia: ${getFormattedAmount(prices.avg)} | max: ${getFormattedAmount(
            prices.max
          )} | min: ${getFormattedAmount(prices.min)}`}
          categoriesLabel={selectedDataType.value === 'data_price' ? 'Cena produktu' : 'Zamówiana ilość'}
          dataLabelsFormatter={selectedDataType.value === 'data_price' ? (value) => getFormattedAmount(value) : undefined}
          yAxisFormatter={selectedDataType.value === 'data_price' ? (value) => getFormattedAmount(value) : undefined}
          categoriesFormatter={categoriesFormatter}
          chartTypes={['area', 'bar']}
          isLoading={isLoading}
          haveData={chartData?.have_data}
          height={400}
          fullWidth
        >
          {isAdmin && (
            <AsyncSelect
              value={selectedCompanyId}
              onChange={setSelectedCompanyId}
              apiCallback={AdminApi.getCompanies}
              queryParams={{ show_deactivated: 0 }}
              className={style.select}
              valueKey={'id'}
              labelKey={'name'}
              placeholder={'Firma'}
              isClearable
            />
          )}
          <Select
            options={options}
            value={selectedDataType}
            onChange={setSelectedDataType}
            isSearchable={false}
            className={style.smallSelect}
            wrapperStyle={style.smallSelect}
            width={200}
          />
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Chart>
      </div>
    </Accordion>
  );
};

export default ProductOrdersChart;
