import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { ReactComponent as CheeringTooth } from 'src/assets/icons/cheering_tooth.svg';
import { ReactComponent as HappyTooth } from 'src/assets/icons/happy_tooth.svg';
import { ReactComponent as PainTooth } from 'src/assets/icons/pain_tooth.svg';
import { ReactComponent as SadTooth } from 'src/assets/icons/sad_tooth.svg';
import { ReactComponent as WaveTooth } from 'src/assets/icons/wave_tooth.svg';

import style from './EmptyState.module.scss';

const EmptyState = ({ type = 'search', className, tiny }) => {
  const imgClasses = classnames(style.img, { [style.tiny]: tiny });

  const content = {
    noReplacements: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak zamienników',
      text: 'Produkt nie posiada zamienników, ponieważ jest jedynym produktem w grupie.'
    },
    replacementsGroupEmpty: {
      image: <SadTooth className={imgClasses} />,
      title: 'Grupa jest pusta',
      text: 'Brak produktów należących do tej grupy'
    },
    emptyReplacementsGroups: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak zdefiniowanych grup zamienników',
      text: 'Kliknij "Zdefiniuj grupę", aby dodać pierwszą.'
    },
    changedPricesProducts: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak produktów'
    },
    departments: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanych jednostek',
      text: 'Kliknij "Dodaj jednostkę", aby dodać pierwszą.'
    },
    eventsAttachments: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak załączników',
      text: 'Dodaj dokumenty związane z usługą.'
    },
    servicesAttachments: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak załączników',
      text: 'Dodaj dokumenty związane ze sprzętem.'
    },
    supplies: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dostaw produktu',
      text: 'Ten produkt nie został jeszcze dodany do stanu magazynowego.'
    },
    releases: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak wydań produktu',
      text: 'Ten produkt nie został jeszcze wyjęty z magazynu.'
    },
    serviceService: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanych serwisów okresowych',
      text: 'W tym miejscu pojawi się historia wszystkich serwisów.'
    },
    serviceFix: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak dodanych napraw',
      text: 'Wybrany sprzęt nie był jeszcze naprawiany.'
    },
    categoryProducts: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak produktów',
      text: 'Ta kategoria nie poisada dodanych produktów'
    },
    maintenance: {
      image: <SadTooth className={imgClasses} />,
      title: 'Przerwa techniczna',
      text: 'Przepraszamy za utrudnienia, wracamy niedługo.'
    },
    maintenanceAvalio: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Przerwa techniczna',
      text: 'Zmieniamy się dla Was! Wracamy niedługo jako Avalio.'
    },
    search: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak wyników wyszukiwania',
      text: 'Nie znaleźliśmy elementów pasujących do wyszukiwanej frazy.'
    },
    OCRProducts: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak produktów',
      text: 'Przeanalizowany dokument nie zawiera produktów.'
    },
    products: {
      image: <PainTooth className={imgClasses} />,
      title: 'Brak Produktów',
      text: 'Możesz dodać produkt klikanjąc przycisk "Dodaj produkt".'
    },
    emptyList: {
      image: <PainTooth className={imgClasses} />,
      title: 'Ta lista jest pusta',
      text: 'Kliknij przycisk "Edytuj", aby dodać produkty do listy.'
    },
    invoicesOCR: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak przeanalizowanych faktur',
      text: 'Kliknij "Dodaj fakturę" aby przeanalizować nowy dokument.'
    },
    offersOCR: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak przeanalizowanych ofert',
      text: 'Kliknij "Dodaj oferty" aby przeanalizować nowy dokument.'
    },
    emptyListEdit: {
      image: <PainTooth className={imgClasses} />,
      title: 'Ta lista jest pusta',
      text: 'Wpisz nazwę produktu, aby dodać go do listy.'
    },
    suppliers: {
      image: <PainTooth className={imgClasses} />,
      title: 'Brak dostawców',
      text: 'Możesz dodać dostawców klikanjąc przycisk "Dodaj dostawcę".'
    },
    wholesalersSuppliers: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak przypisanych przedstawicieli'
    },
    warehouse: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak produktów w magazynie',
      text: 'Kliknij przycisk "Dodaj produkt" aby dodać produkt do magazynu.'
    },
    warehouseWithFilters: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak wyników',
      text: 'Brak produktów pasujących do podanych parametrów'
    },
    lists: {
      image: <PainTooth className={imgClasses} />,
      title: 'Nie masz żadnych list',
      text: 'Kliknij przycisk "Utwórz listę" aby stworzyć pierwszą.'
    },
    valuations: {
      image: <SadTooth className={imgClasses} />,
      title: 'Nie masz żadnych wycen',
      text: 'Przejdź do List, i zamów wycenę potrzebnych produktów.'
    },
    supplierValuations: {
      image: <SadTooth className={imgClasses} />,
      title: 'Nie masz żadnych list do wyceny',
      text: 'Pojawią się tutaj, jeśli któryś z klientów poprosi cię o wycenę'
    },
    orders: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Nie masz jeszcze żadnych zamówień',
      text: 'Aby złożyć zamówienie przejdź do zakładki Wyceny.'
    },
    distrubutors: {
      image: <PainTooth className={imgClasses} />,
      title: 'Brak dystrybutorów',
      text: 'Dodaj dystrybutora klikając przycisk "Dodaj dystrybutora".'
    },
    distrubutorsList: {
      image: <PainTooth className={imgClasses} />,
      title: 'Nie masz więcej dostawców',
      text: (
        <>
          Przejdź do zakładki
          <Link to={'/suppliers'}> Dostawcy </Link>
          aby dodać nowego.
        </>
      )
    },
    listValuation: {
      image: <PainTooth className={imgClasses} />,
      title: 'Nie masz dodanych dostawców',
      text: (
        <>
          Przejdź do zakładki
          <Link to={'/suppliers'}> Dostawcy </Link>
          aby ich dodać.
        </>
      )
    },
    substitute: {
      image: <PainTooth className={imgClasses} />,
      title: 'Brak dostępnych zamienników',
      text: ''
    },
    supplierOrderSent: {
      image: <WaveTooth className={imgClasses} />,
      title: 'Zamówienie skompletowane',
      text: 'Oczekiwanie na odebranie produktów przez użytkownika.'
    },
    supplierOrderCompleted: {
      image: <CheeringTooth className={imgClasses} />,
      title: 'Zamówienie zrealizowane',
      text: 'Użytkownik otrzymał swoje produkty.'
    },
    supplierOrderCancelled: {
      image: <SadTooth className={imgClasses} />,
      title: 'Zamówienie zostało anulowane',
      text: 'Dostawca nie podjął się realizacji tego zamówienia.'
    },
    supplierOrderNotFound: {
      image: <PainTooth className={imgClasses} />,
      title: 'Nie znaleziono zamówienia',
      text: 'Coś poszło nie tak... Skontaktuj się z nami.'
    },
    404: {
      image: <PainTooth className={imgClasses} />,
      title: 'Coś poszło nie tak... Nie ma takiej strony.',
      text: ''
    },
    warehouseHistory: {
      image: <PainTooth className={imgClasses} />,
      title: 'Brak danych',
      text: 'Nie posiadasz żadnych produktów w magazynie'
    },
    warehouseHistoryWelcome: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Wybierz zakres dat',
      text: 'Aby zobaczyć historyczne dane magazynowe.'
    },
    supplierClients: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak połączonych klientów',
      text: 'Pojawią się tu po wysłaniu pierwszej wyceny na adres dostawcy.'
    },
    users: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak użytkowników',
      text: 'Do podanych parametrów nie pasuje żaden użytkownik'
    },
    members: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak użytkowników',
      text: 'Wybrany oddział nie posiada przypisanych członków.'
    },
    notificationsCenter: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak powiadomień',
      text: 'Aktualnie nie masz żadnych powiadomień.'
    },
    unreadNotifications: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Jesteś na bieżąco',
      text: 'Aktualnie nie masz żadnych nieodczytanych powiadomień.'
    },
    errorFallback: {
      image: <PainTooth className={imgClasses} />,
      title: 'Wystąpił nieoczekiwany błąd.',
      text: 'Wróć na stronę główną.'
    },
    companies: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak firm',
      text: 'Do wybranych parametrów nie pasuje żaden oddział.'
    },
    filtersEmpty: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak wyników',
      text: 'Do wybranych parametrów nie pasuje żaden wynik.'
    },
    collections: {
      image: <PainTooth className={imgClasses} />,
      title: 'Brak produktów w kolekcji',
      text: (
        <>
          Przejdź do zakładki
          <Link to={'/supplier/products'}> Baza produktów </Link>
          aby dodać je do kolekcji.
        </>
      )
    },
    clients: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak klientów',
      text: 'Jeszcze żadna firma nie nawiązała z Tobą kontaktu.'
    },
    suppliersManagements: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak produktów',
      text: 'Nie ma jeszcze dodanych produktów.'
    },
    invoices: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanych faktur',
      text: 'Możesz dodać historyczne dane klikająć przycisk "Dodaj fakturę".'
    },
    selectCompany: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Wybierz firmę',
      text: 'Aby przeglądać jej katalog ofert'
    },
    selectCompanyWarehouse: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Wybierz firmę',
      text: 'Aby przeglądać jej magazyn'
    },
    selectCompanyServices: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Wybierz firmę',
      text: 'Aby przeglądać jej serwisy'
    },
    selectCompanyTags: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Wybierz firmę',
      text: 'Aby przeglądać jej tagi'
    },
    selectCompanyForAnalytics: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Wybierz firmę',
      text: 'Aby przeglądać jej statystyki'
    },
    noPrices: {
      image: <SadTooth className={imgClasses} />,
      title: 'Nie znaleziono ofert pasujących do Twoich kryteriów',
      text: 'Spróbuj dostosować filtry lub zmienić frazę wyszukiwania.'
    },
    noPricesSelected: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak wybranych produktów',
      text: 'Znacz produkty, które chcesz zamówić.'
    },
    supplierChoice: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dostawców do wyboru',
      text: 'Pozyskaj ceny od innych dostawców na ten produkt aby móc wybrać z większej puli.'
    },
    orderHistory: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak historii zamówienia',
      text: 'To zamówienie nie posiada zapisanej historii.'
    },
    expensesHistory: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak historii wydatków'
    },
    servicesHistory: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak historii sprzętu'
    },
    tagsHistory: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak historii tagów'
    },
    expenses: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanych wydatków'
    },
    wholesalers: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanych hurtownii'
    },
    services: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanego sprzętu',
      text: 'Nie dodano żadnego sprzętu.'
    },
    servicers: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dodanych serwisantów',
      text: 'Żaden serwisant nie został dodany.'
    },
    wholesalersProducts: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak produktów przypisanych do tej hurtownii'
    },
    thresholds: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak progów akceptacji zamówienia',
      text: 'Żadne zamówienie nie wymaga akceptacji.'
    },
    ordersApproval: {
      image: <HappyTooth className={imgClasses} />,
      title: 'Brak zamówień do akceptacji',
      text: 'Żadne zamówienie nie wymaga akceptacji.'
    },
    prices: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak historii cen produktu',
      text: 'Do wybranego produktu nie ma przypisanej żadnej ceny.'
    },
    orderInvoice: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak faktury do zamówienia',
      text: 'Dodaj dokument, aby mieć wszystkie informacje w jednym miejscu'
    },
    chart: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak danych',
      text: 'Brak danych do zaprezentowania.'
    },
    creators: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak osób, które mogą wysłać wycenę',
      text: 'Twój oddział nie posiada przypisanych użytkowników, którzy mają uprawnienia do wysyłania wycen do dostawców. Skontaktuj się z supportem.'
    },
    suppliersSelect: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dostawców',
      text: 'Nie możesz wysłać wyceny, ponieważ oddział nie ma dodanych dostawców.'
    },
    suppliersVisibility: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak dostawców',
      text: 'Nie możesz ukryć ofert, ponieważ oddział nie ma dodanych dostawców.'
    },
    avgSupplierTimeChart: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak danych do wyświetlenia',
      text: 'Proszę poczekać, aż system zgromadzi wystarczającą ilość informacji.'
    },
    topProductsWithFilters: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak danych do wyświetlenia',
      text: 'Do wybranych filtrów nie dopasowano żadnego produktu.'
    },
    topProducts: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak danych do wyświetlenia',
      text: 'Aktualnie nie możesz wyświetlić zestawiania produktów.'
    },
    pendingValuations: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak aktywnych wycen',
      text: ''
    },
    volumes: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak historii wolumenów',
      text: 'Wybrany produkt nie posiada historii wolumenów.'
    },
    tags: {
      image: <SadTooth className={imgClasses} />,
      title: 'Brak zdefiniowanych tagów',
      text: 'Kliknij "Zdefiniuj tag", aby dodać pierwszy.'
    }
  };

  const getContent = () => {
    try {
      return content[type];
    } catch (err) {
      return { image: '', title: '', text: '' };
    }
  };

  const { image, title, text } = useMemo(getContent, [type]);

  return (
    <div className={classnames(style.container, { [style.tiny]: tiny }, className)}>
      {image}
      <h3>{title}</h3>
      <p className={style.text}>{text}</p>
    </div>
  );
};

export default EmptyState;
