import React, { useEffect, useState } from 'react';
import Loader from 'react-loading-skeleton';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { AsyncSelect, Button, Card, DateRangePicker, EmptyState, PaginationNav, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, ProductsApi, StatisticsApi, UserApi, WholesalersApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import PricesChangeSummaryThumbnail from './components/PricesChangeSummaryThumbnail';

import styles from './PricesChangeSummary.module.scss';
import shared from 'styles/Shared.module.scss';

const priceTypesOptions = [
  { label: 'Zmieniona przez admina w wycenie', value: 'ADMIN_VALUATION' },
  { label: 'Zmieniona przez dostawcę w wycenie', value: 'SUPPLIER_VALUATION' },
  { label: 'Zmieniona przez dostawcę w zamówieniu (akceptacja klienta)', value: 'USER_ACCEPT_NEW_PRICE' },
  { label: 'Zmieniona przez dostawcę w zamówieniu', value: 'SUPPLIER_ORDER' },
  { label: 'Zmieniona przez admina (ręcznie)', value: 'ADMIN_ACCEPT_NEW_PRICE' },
  { label: 'Zmieniona przez admina (import cen)', value: 'ADMIN_IMPORT_CATALOG_PRICE' },
  { label: 'Zmieniona przez admina w widoku klienta (import cen)', value: 'ADMIN_CLIENT_LOGIN_IMPORT_CATALOG_PRICE' },
  { label: 'Zmieniona przez użytkownika (import cen)', value: 'USER_IMPORT_CATALOG_PRICE' },
  { label: 'Zmieniona przez użytkownika (panel Ofert)', value: 'USER_ADD_PRICE_OFC' },
  { label: 'Zmieniona przez admina (panel Ofert)', value: 'ADMIN_ADD_PRICE_OFC' },
  { label: 'Zmieniona przez admina w widoku klienta (panel Ofert)', value: 'ADMIN_CLIENT_LOGIN_ADD_PRICE_OFC' },
  { label: 'Zmieniona przez admina w widoku klienta (Wyceny)', value: 'ADMIN_CLIENT_LOGIN_VALUATION' }
];

const PricesChangeSummary = () => {
  const [data, setData] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(15);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedWholesalers, setSelectedWholesalers] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [priceTypes, setPriceTypes] = useState([]);

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const [isXLSXLoading, setIsXLSXLoading] = useState(false);

  const date = dayjs().format('DD.MM.YYYY, HH:mm');

  const getData = async () => {
    const params = {
      page: currentPage,
      perPage,
      ...(startDate && endDate && { date_from: dayjs(startDate).toISOString(), date_to: dayjs(endDate).toISOString() }),
      ...(selectedCompanies.length > 0 && { company_ids: selectedCompanies.map((company) => company.id) }),
      ...(selectedWholesalers.length > 0 && { wholesaler_ids: selectedWholesalers.map((wholesaler) => wholesaler.id) }),
      ...(selectedProducts.length > 0 && { product_ids: selectedProducts.map((product) => product.id) }),
      ...(selectedSuppliers.length > 0 && { supplier_ids: selectedSuppliers.map((supplier) => supplier.id) }),
      ...(selectedSuppliers.length > 0 && { supplier_ids: selectedSuppliers.map((supplier) => supplier.id) }),
      ...(priceTypes.length > 0 && { add_type_filter: priceTypes.map((type) => type.value) })
    };

    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getPricesChangesStatistics(params);
      setPageQty(data.last_page);
      setData(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCSV = async (data) => {
    const blob = new Blob([data], { type: 'text/csv' });
    downloadFile(blob, `zmiany-cen-${date}` + '.csv');
  };
  const downloadXLSX = async (data) => {
    const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
    downloadFile(blob, `zmiany-cen-${date}` + '.xlsx');
  };

  const downloadHandler = async (file_format) => {
    const params = {
      ...(startDate && endDate && { date_from: dayjs(startDate).toISOString(), date_to: dayjs(endDate).toISOString() }),
      ...(selectedCompanies.length > 0 && { company_ids: selectedCompanies.map((company) => company.id) }),
      ...(selectedWholesalers.length > 0 && { wholesaler_ids: selectedWholesalers.map((wholesaler) => wholesaler.id) }),
      ...(selectedProducts.length > 0 && { product_ids: selectedProducts.map((product) => product.id) }),
      ...(selectedSuppliers.length > 0 && { supplier_ids: selectedSuppliers.map((supplier) => supplier.id) }),
      ...(priceTypes.length > 0 && { add_type_filter: priceTypes.map((type) => type.value) }),
      file_format
    };

    try {
      file_format === 'CSV' ? setIsCSVLoading(true) : setIsXLSXLoading(true);
      const { data } = await StatisticsApi.exportPricesChangesStatistics(params);
      file_format === 'CSV' ? downloadCSV(data) : downloadXLSX(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      file_format === 'CSV' ? setIsCSVLoading(false) : setIsXLSXLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, startDate, endDate, selectedCompanies, selectedWholesalers, selectedProducts, selectedSuppliers, priceTypes]);

  useEffect(() => {
    if (currentPage === 1) return;
    setCurrentPage(1);
  }, [startDate, endDate, selectedCompanies, selectedWholesalers, selectedProducts, selectedSuppliers, priceTypes]);

  return (
    <Card className={styles.container}>
      <div className={styles.moduleHeader}>
        <h2 className={styles.title}>Analityka zmian cen</h2>
        <div className={styles.exportingButtons}>
          <Button
            label='Eksport *.csv'
            isLoading={isCSVLoading}
            onClick={() => downloadHandler('CSV')}
          />
          <Button
            label='Eksport *.xlsx'
            isLoading={isXLSXLoading}
            onClick={() => downloadHandler('XLSX')}
          />
        </div>
      </div>
      <div className={styles.filtering}>
        <DateRangePicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <AsyncSelect
          id={'select-product'}
          value={selectedProducts}
          onChange={setSelectedProducts}
          apiCallback={ProductsApi.getProducts}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Produkt'}
          productSearch
          isClearable
          isMulti
        />
        <AsyncSelect
          value={selectedCompanies}
          onChange={setSelectedCompanies}
          apiCallback={AdminApi.getCompanies}
          queryParams={{ show_deactivated: 0 }}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Firma'}
          isClearable
          isMulti
          id={'company-select'}
          name={'company-select'}
        />
        <AsyncSelect
          apiCallback={WholesalersApi.getWholesalers}
          onChange={setSelectedWholesalers}
          value={selectedWholesalers}
          placeholder={'Hurtownia'}
          labelKey={'name'}
          valueKey={'id'}
          name={'wholesaler-select'}
          id={'wholesaler-select'}
          isClearable
          isMulti
        />
        <AsyncSelect
          value={selectedSuppliers}
          onChange={setSelectedSuppliers}
          apiCallback={UserApi.getDistributors}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Dostawca'}
          queryParams={{ pagination: 1, perPage: 10 }}
          name={'supplier-select'}
          id={'supplier-select'}
          isClearable
          isMulti
        />
        <Select
          options={priceTypesOptions}
          isSearchable={false}
          isClearable
          value={priceTypes}
          onChange={setPriceTypes}
          placeholder={'Typ zmiany'}
          id={'add_type'}
          name={'add_type'}
          isMulti
        />
      </div>
      {isLoading ? (
        <Loader
          count={15}
          height={30}
          containerClassName={styles.loader}
        />
      ) : data.length < 1 ? (
        <EmptyState
          tiny
          type={'filtersEmpty'}
          className={styles.empty}
        />
      ) : (
        <div className={classNames(shared.tableWrapper, styles.table)}>
          <div className={classNames(shared.tableHeader, styles.grid)}>
            <p>Organizacja</p>
            <p>Produkt</p>
            <p>Data zmiany</p>
            <p>Przed</p>
            <p>Po</p>
            <p>Różnica</p>
            <p>Dostawca</p>
            <p>Hurtownia</p>
          </div>
          <div className={shared.tableCategory}>
            {data.map((item, i) => (
              <PricesChangeSummaryThumbnail
                key={`${item.product_id}${i}`}
                item={item}
              />
            ))}
          </div>
          <PaginationNav
            setPage={setCurrentPage}
            pagesQty={pageQty}
            setPerPage={setPerPage}
            defaultQty={perPage}
            page={currentPage}
            className={styles.pagination}
            hideSelect
          />
        </div>
      )}
    </Card>
  );
};

export default PricesChangeSummary;
